import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import LinearDeterminate from './LinearDeterminate';

const MainPage = ({ mainFields, next }): JSX.Element => {
    const [isReady, setIsReady] = useState(false);
    const [imgHeight, setImgHeight] = useState(0);
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isPhone = useMediaQuery({ maxWidth: 950 });
    const seconds = 30;

    const Desktop = ({ children }) => (isLandscape ? children : null);

    const Portrait = ({ children }) => (isPortrait ? children : null);

    useEffect(() => {
        if (!isReady) {
            const { innerWidth: width, innerHeight: height } = window;
            setImgHeight(height * 0.6);
        }

        const timer = setTimeout(() => {
            setIsReady(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, [isReady]);

    const containerStylesPortrait = {
        margin: 10,
        width: '90%',
        display: 'flex'
    };

    return (
        <>
            <Desktop>
                <Typography
                    variant="h2"
                    dangerouslySetInnerHTML={{
                        __html: mainFields.title
                    }}
                    style={{ marginBottom: isPhone && isLandscape ? 2 : 10 }}
                />
                <Typography variant="h5">
                    Merke dir 30 Sekunden lang möglichst viel von diesem Bild.
                </Typography>
                <Grid container justify="center">
                    <Grid item>
                        <img
                            style={{
                                maxWidth: '100%',
                                maxHeight: '90%',
                                textAlign: 'center',
                                height: imgHeight
                            }}
                            src={mainFields.hauptbild.url}
                            alt="Zeitfensterbild"
                            className={!isReady ? 'fade-in-image' : ''}
                        />
                        <div>
                            <Typography
                                variant="subtitle2"
                                dangerouslySetInnerHTML={{
                                    __html: mainFields.beschreibung
                                }}
                                style={{ textAlign: 'center' }}
                            />
                        </div>
                    </Grid>
                </Grid>

                {isReady && (
                    <LinearDeterminate
                        seconds={seconds}
                        isDone={next}
                        fullWidth
                        style={{
                            marginTop: isPhone ? 0 : -20,
                            marginLeft: 20,
                            marginRight: 20
                        }}
                    />
                )}
            </Desktop>
            <Portrait>
                <Grid container justify="center">
                    <Grid item>
                        <Typography
                            variant="h4"
                            dangerouslySetInnerHTML={{
                                __html: mainFields.title
                            }}
                        />
                        <div style={containerStylesPortrait}>
                            <img
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%'
                                }}
                                src={mainFields.hauptbild.url}
                                alt="Zeitfensterbild"
                                className="fade-in-image"
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div
                            style={{
                                paddingLeft: 10
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                dangerouslySetInnerHTML={{
                                    __html: mainFields.beschreibung
                                }}
                                style={{ textAlign: 'left' }}
                            />
                        </div>
                    </Grid>
                </Grid>

                {isReady && (
                    <LinearDeterminate
                        seconds={seconds}
                        isDone={next}
                        fullWidth
                        style={{
                            marginTop: 10,
                            marginLeft: 20,
                            marginRight: 20
                        }}
                    />
                )}
            </Portrait>
        </>
    );
};

export default MainPage;
