import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import TimeBoxPage from './pages/TimeBoxPage';
import Homepage from './pages/HomePage';
import TimeWindowPage from './pages/TimeWindowPage';
import MotifFinderPage from './pages/MotifFinderPage';
import TimeLinePage from './pages/TimeLinePage';
import TracingPage from './pages/TracingPage';
import MiniGamesCarousel from './animations/Carousel/MiniGamesCarousel';

const App = (): JSX.Element => (
    <div className="App">
        <Router basename="/">
            <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/zeitfenster" component={TimeWindowPage} />
                <Route exact path="/zeitkapsel" component={TimeBoxPage} />
                <Route exact path="/motivsucher" component={MotifFinderPage} />
                <Route exact path="/zeitstrahl" component={TimeLinePage} />
                <Route exact path="/spurensuche" component={TracingPage} />
                <Route exact path="/carousel" component={MiniGamesCarousel} />
            </Switch>
        </Router>
    </div>
);

export default App;
