import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Logo from '../assets/stadtmuseum_graz_logo.png';
import guide from '../assets/Spieleanleitung.svg';
import overView from '../assets/Ubersicht.svg';
import overViewMobile from '../assets/Uebersicht-mobile.svg';
import guideMobile from '../assets/Spieleanleitung-mobile.svg';

const HeaderPage = ({
    text,
    showInfoPage,
    color,
    smallHeader
}): JSX.Element => {
    const history = useHistory();
    const isPhone = useMediaQuery({ maxWidth: 1123 });

    return (
        <div>
            <Helmet>
                <style>{`body { background-image: url("${color}"); background-size:cover;
             }`}</style>
            </Helmet>
            <Grid
                container
                alignContent="center"
                justify="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={3}
                    lg={3}
                    style={{
                        textAlign: 'left',
                        paddingLeft: isPhone ? 5 : 42,
                        paddingTop: isPhone ? 5 : 24
                    }}
                >
                    <a href="https://www.grazmuseum.at/jungestadt/">
                        <img
                            src={Logo}
                            alt="Stadtmuseum Graz Logo"
                            width={isPhone ? 50 : 100}
                        />
                    </a>
                </Grid>
                <Grid item xs={5} sm={6} lg={6}>
                    <Typography variant={smallHeader ? 'h5' : 'h1'}>
                        {text}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={3} lg={3}>
                    <div>
                        <IconButton
                            style={{ padding: 0, marginLeft: isPhone ? 34 : 0 }}
                            onClick={showInfoPage}
                        >
                            <img
                                style={{
                                    paddingTop: isPhone ? 18 : 22,
                                    margin: isPhone ? -10 : 0
                                }}
                                src={isPhone ? guideMobile : guide}
                                alt="Anleitung"
                            />
                        </IconButton>
                        <IconButton
                            style={{ padding: 0 }}
                            aria-label="question"
                        >
                            <a href="https://www.grazmuseum.at/jungestadt/mini-games/">
                                <img
                                    src={isPhone ? overViewMobile : overView}
                                    alt="Übersicht"
                                />
                            </a>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default HeaderPage;
