import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import lerp from 'lerp';
import Confetti from './Confetti';

const AppStyled = styled.div`
    padding: 2rem;
`;

const EndAnimation = ({ seconds }) => {
    const [update, setUpdate] = useState(0);
    const [isFinished, setIsFinished] = useState(false);

    const screenSizeMultiplier = window.innerWidth / 800;

    const burstAmount = Math.floor(
        lerp(100, 150, Math.random() * screenSizeMultiplier)
    );
    const afterBurstAmount = Math.floor(
        lerp(40, 120, Math.random() * screenSizeMultiplier)
    );

    useEffect(() => {
        setTimeout(() => setIsFinished(true), seconds * 1_000);
    }, [seconds]);

    return (
        <AppStyled>
            {isFinished ? null : (
                <Confetti
                    key={update}
                    burstAmount={burstAmount}
                    afterBurstAmount={afterBurstAmount}
                    onEnd={() => {
                        setUpdate(update + 1);
                    }}
                />
            )}
        </AppStyled>
    );
};

export default EndAnimation;
