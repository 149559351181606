import { Chrono } from 'react-chrono';

export default function ChronoTimeline({
    timelineItemWidth,
    mode,
    width,
    height,
    items
}) {
    return (
        <>
            <div
                style={{
                    width,
                    height,
                    marginTop: mode === 'HORIZONTAL' ? 5 : 0
                }}
            >
                <Chrono
                    items={items}
                    mode={mode}
                    hideControls
                    disableNavOnKey
                    scrollable={false}
                    useReadMore={false}
                    cardHeight={50}
                    itemWidth={timelineItemWidth}
                    theme={{
                        primary: 'white',
                        secondary: '#F0D232',
                        cardBgColor: '#ffffff',
                        cardForeColor: 'black'
                    }}
                    cardPositionHorizontal="BOTTOM"
                />
            </div>
        </>
    );
}
