import React from 'react';

const horizontalStyles = {
    backgroundColor: 'white',
    height: 2,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 10,
    border: '5px solid #FFFFFF'
};

const verticalStyles = {
    ...horizontalStyles,
    width: 2,
    height: '75vh'
};

const ColoredLine = ({ isVertical = false }) => (
    <>
        <hr style={isVertical ? verticalStyles : horizontalStyles} />
    </>
);

export default ColoredLine;
