import React from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import EndAnimation from '../animations/EndAnimation';
import { getLogoUrlByGameName } from '../util';

const InfoTextPage = ({
    next,
    isLastPage = false,
    text,
    finalWord = '',
    imageUrl,
    counter = -1,
    maxCounter = -1,
    blob,
    hasNoMoreGames,
    gameName
}): JSX.Element => {
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxHeight: 768 });
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isPhoneLandscape = useMediaQuery({ maxWidth: 950, maxHeight: 500 });

    const PhoneLandscape = ({ children }) =>
        isPhone && isLandscape ? children : null;

    const Normal = ({ children }) =>
        !(isPhone && isLandscape) ? children : null;

    return (
        <>
            <Normal>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                        minHeight: isLandscape ? '80vh' : 0,
                        marginTop: !isDesktop ? 50 : 0
                    }}
                >
                    <Grid item xs={10} lg={7}>
                        <img
                            src={imageUrl}
                            alt="zeigt ein historisches Bild an"
                            style={{
                                maxHeight: '60vh',
                                width: isPhone ? '100%' : 'auto'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} xl={4}>
                        {text !== '' && (
                            <Typography
                                style={{
                                    textAlign: 'left',
                                    padding:
                                        isLandscape && isTabletLandscape
                                            ? '0 15%'
                                            : '20px 0px 0px 15px'
                                }}
                                variant="h2"
                                dangerouslySetInnerHTML={{
                                    __html: text
                                }}
                            />
                        )}
                        {isLastPage && counter >= 0 && (
                            <Typography variant="h2" style={{ marginTop: 20 }}>
                                Herzlichen Glückwunsch! Du hast{' '}
                                <strong>{counter}</strong> von{' '}
                                <strong>{maxCounter}</strong> Bilder richtig
                                zugewiesen!
                            </Typography>
                        )}
                        {finalWord && (
                            <Typography
                                style={{
                                    textAlign: 'left',
                                    padding:
                                        isLandscape && isPhone
                                            ? '0 20%'
                                            : '20px 0px 0px 15px'
                                }}
                                variant="h2"
                                dangerouslySetInnerHTML={{
                                    __html: finalWord
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                {isLastPage ? (
                    <>
                        <EndAnimation seconds={10} />
                        <Button
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 10
                            }}
                            onClick={next}
                            disabled={hasNoMoreGames}
                        >
                            <img
                                src={getLogoUrlByGameName(gameName).right}
                                alt="Pfeilicon"
                                width={isPhone ? 70 : 130}
                            />
                        </Button>
                    </>
                ) : (
                    <Button
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 10,
                            opacity: hasNoMoreGames ? 0.2 : 1
                        }}
                        onClick={next}
                    >
                        <img
                            src={getLogoUrlByGameName(gameName).right}
                            alt="Pfeilicon"
                            width={isPhone ? 70 : 130}
                        />
                    </Button>
                )}
            </Normal>
            <PhoneLandscape>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: isLandscape && !isPhone ? '85vh' : 0 }}
                >
                    <Grid item xs={5}>
                        <img
                            src={imageUrl}
                            alt="zeigt ein historisches Bild an"
                            width="100%"
                            style={{
                                width: isLandscape ? '100%' : '70%',
                                height: isLandscape ? '80vh' : '50hv',
                                objectFit: 'scale-down'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ paddingLeft: 50, textAlign: 'left' }}>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: text
                                }}
                            />
                            {isLastPage && counter >= 0 && (
                                <Typography style={{ marginTop: 20 }}>
                                    Herzlichen Glückwunsch! Du hast{' '}
                                    <strong>{counter}</strong> von{' '}
                                    <strong>{maxCounter}</strong> Bilder richtig
                                    zugewiesen!
                                </Typography>
                            )}
                            {finalWord && (
                                <Typography
                                    style={{
                                        textAlign: 'left',
                                        padding: '20px 20px 0 30px'
                                    }}
                                    variant="h4"
                                    dangerouslySetInnerHTML={{
                                        __html: finalWord
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                {isLastPage ? (
                    <>
                        <EndAnimation seconds={18} />
                        <Button
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 10
                            }}
                            onClick={next}
                            disabled={hasNoMoreGames}
                        >
                            <img
                                src={getLogoUrlByGameName(gameName).right}
                                alt="Pfeilicon"
                                width={isPhone ? 70 : 130}
                            />
                        </Button>
                    </>
                ) : (
                    <Button
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 10,
                            opacity: hasNoMoreGames ? 0.2 : 1
                        }}
                        onClick={next}
                    >
                        <img
                            src={getLogoUrlByGameName(gameName).right}
                            alt="Pfeilicon"
                            width={
                                isPhone || isPhoneLandscape || isTabletLandscape
                                    ? 70
                                    : 130
                            }
                        />
                    </Button>
                )}
            </PhoneLandscape>
        </>
    );
};

export default InfoTextPage;
