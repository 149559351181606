import React from 'react';
import ReactCardFlip from 'react-card-flip';
import { Typography } from '@material-ui/core';
import { DropTarget } from 'react-drag-drop-container';
import { useMediaQuery } from 'react-responsive';

const tileStyles = {
    width: 104,
    height: 104,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '5px solid #DBDBDB',
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const tileStylesPhoneLandscape = {
    ...tileStyles,
    width: 80,
    height: 80
};

export default ({ index, onHit, isFlipped, cardData, isPhoneLandscape }) => {
    const isPhone = useMediaQuery({ maxWidth: 850 });

    const styles = isPhoneLandscape ? tileStylesPhoneLandscape : tileStyles;

    return (
        <DropTarget
            targetKey={`target${index}`}
            onDragEnter={onHit}
            onDragLeave={() => {}}
            key={`dropTarget_timeline_${index}`}
        >
            <ReactCardFlip isFlipped={isFlipped}>
                <div
                    style={{
                        ...styles,
                        ...{
                            width: isPhone ? 75 : 104,
                            height: isPhone ? 75 : 104
                        }
                    }}
                >
                    <Typography variant="h3" />
                </div>

                <div
                    style={{
                        ...styles,
                        ...{
                            width: isPhone ? 75 : 104,
                            height: isPhone ? 75 : 104
                        }
                    }}
                >
                    <img
                        src={
                            cardData.historisches_bild.miniaturbild.sizes
                                .thumbnail
                        }
                        alt={cardData.historisches_bild.miniaturbild.alt}
                    />
                </div>
            </ReactCardFlip>
        </DropTarget>
    );
};
