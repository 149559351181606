import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/styles';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import theme from './theme';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
