import { useEffect, useState } from 'react';

import pinkArrowLeft from './assets/arrows/pink_arrow_left.png';
import pinkArrowRight from './assets/arrows/pink_arrow_right.png';
import yellowArrowRight from './assets/zeitfenster/yellow_right.png';
import yellowArrowLeft from './assets/zeitfenster/yellow_left.png';
import redArrowLeft from './assets/arrows/red_left.png';
import redArrowRight from './assets/arrows/red_right.png';
import greenArrowLeft from './assets/arrows/green_left.png';
import greenArrowRight from './assets/arrows/green_right.png';
import blueArrowLeft from './assets/arrows/blue_left.png';
import blueArrowRight from './assets/arrows/blue_right.png';

import motfFinderBG from './assets/motivsucher/bg.png';
import timewindowBG from './assets/zeitfenster/bg.png';
import timeBoxBG from './assets/zeitkapsel/bg.png';
import timeLineBG from './assets/zeitstrahl/bg.png';
import tracingBG from './assets/spurensuche/bg.png';

export const randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

export function* shuffle(array) {
    let i = array.length;
    // eslint-disable-next-line no-plusplus
    while (i--) {
        yield array.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
    }
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
};

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffleArray = (a) => {
    const tmpArray = a;
    for (let i = tmpArray.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [tmpArray[i], tmpArray[j]] = [tmpArray[j], tmpArray[i]];
    }
    return tmpArray;
};

export const isArrayEmpty = (array) => Array.isArray(array) && array.length;

export const getFontSize = (isLandscape) => (isLandscape ? '2.5vw' : '2.5vh');
export const getFontSizeForHeader = (isLandscape) =>
    isLandscape ? '2.7vw' : '2.7vh';

export const removeItem = <T extends any>(
    arr: Array<T>,
    value: T
): Array<T> => {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

export const removeIndex = <T extends any>(
    arr: Array<T>,
    index: number
): Array<T> => {
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

export const getLogoUrlByGameName = (name: string) => {
    switch (name) {
        case 'zeitkapsel':
            return {
                left: greenArrowLeft,
                right: greenArrowRight
            };
        case 'zeitstrahl':
            return {
                left: pinkArrowLeft,
                right: pinkArrowRight
            };
        case 'motivsucher':
            return {
                left: redArrowLeft,
                right: redArrowRight
            };
        case 'spurensuche':
            return {
                left: blueArrowLeft,
                right: blueArrowRight
            };
        case 'zeitfenster':
            return {
                left: yellowArrowLeft,
                right: yellowArrowRight
            };
        default:
            return {
                left: greenArrowLeft,
                right: greenArrowRight
            };
    }
};

export const getBackgroundImageByName = (name: string) => {
    switch (name) {
        case 'zeitkapsel':
            return timeBoxBG;
        case 'zeitstrahl':
            return timeLineBG;
        case 'motivsucher':
            return motfFinderBG;
        case 'spurensuche':
            return tracingBG;
        case 'zeitfenster':
            return timewindowBG;
        default:
            return timewindowBG;
    }
};
