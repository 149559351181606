import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import InfoPage from './InfoPage';
import HeaderPage from './HeaderPage';
import MainPage from '../timeWindow/MainPage';
import QuestionPage from '../timeWindow/QuestionPage';
import AnswerPage from '../timeWindow/AnswerPage';
import InfoTextPage from './InfoTextPage';
import { shuffle } from '../util';
import Blob9 from '../assets/GMU-JS-blob-9.png';
import { useQuery } from '../utils';
import descriptionBG from '../assets/zeitfenster/Spielanleitung.png';
import gameBG from '../assets/zeitfenster/zeitfenster.png';
import gameBG2 from '../assets/zeitfenster/zeitfenster2.png';

const TimeWindowPage = (): JSX.Element => {
    const query = useQuery();
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const [pageCounter, setPageCounter] = useState(0);
    const [isInfoPage, setIsInfoPage] = useState(true);
    const [answerText, setAnswerText] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [questionCounter, setQuestionCounter] = useState(1);
    const [numberOfGames, setNumberOfGames] = useState(0);
    const bodyColor = '#AF9BC8';
    const headerText = 'Zeitfenster';
    let id = 0;

    const paramId = query.get('id');
    if (data && paramId !== null && !Number.isNaN(paramId)) {
        const parseToInt = Number.parseInt(paramId, 10);
        if (
            !Number.isNaN(parseToInt) &&
            parseToInt < numberOfGames &&
            parseToInt >= 0
        )
            id = Number.parseInt(paramId, 10);
    }

    const randomNumberGenerator = useMemo(
        () => shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
        []
    );

    const handleNextPage = () => {
        setPageCounter(pageCounter + 1);
        setIsInfoPage(false);
    };

    const handleNextQuestion = () => {
        if (questionCounter === data.zeitfenster_katalog_fragen.length) {
            handleNextPage();
        } else {
            setPageCounter(pageCounter - 1);
            setQuestionCounter(questionCounter + 1);
        }
    };

    const handleNextGame = () => {
        if (id < numberOfGames - 1) {
            id += 1;
        } else {
            id = 0;
        }
        history.push(`/zeitfenster?id=${id}`);
        window.location.reload();
    };

    const handleShowInfoPage = () => {
        setIsInfoPage(true);
        setPageCounter(pageCounter - 1);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await axios.get(
                    `${process.env.REACT_APP_ACF_URL}zeitfenster?per_page=100`
                );
                if (fetchedData.status === 200) {
                    const mappedData = fetchedData.data.map(
                        (el) => el.acf.zeitfensterspiele[0].einzelspiele
                    );

                    setData(mappedData[id]);
                    setNumberOfGames(mappedData.length);
                    setIsLoaded(true);
                } else {
                    setShowError(true);
                }
            } catch (e) {
                setShowError(true);
            }
        }

        fetchData().then();
    }, [id]);

    return (
        <>
            {showError && (
                <Alert severity="error">
                    Spiel konnte nicht geladen werden. Überprüfe bitte deine
                    Internetverbindung.
                </Alert>
            )}
            {isLoaded && (
                <div>
                    {isInfoPage && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={descriptionBG}
                                smallHeader={false}
                            />
                            <InfoPage
                                description={data.startseite_beschreibungstext}
                                manual={data.startseite_spielanleitung}
                                next={handleNextPage}
                                gameName="zeitfenster"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 1 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <MainPage
                                mainFields={data.hauptseite_felder}
                                next={handleNextPage}
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 2 && (
                        <>
                            <HeaderPage
                                text={`${headerText} - Frage ${questionCounter} von 
                                ${data.zeitfenster_katalog_fragen.length}`}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader
                            />
                            <QuestionPage
                                questions={data.zeitfenster_katalog_fragen}
                                randomNumberGenerator={randomNumberGenerator}
                                next={handleNextPage}
                                updateAnswer={(text) => setAnswerText(text)}
                                updateImage={(url) => setImageUrl(url)}
                                updateCorrectAnswer={(text) =>
                                    setCorrectAnswer(text)
                                }
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 3 && (
                        <>
                            <HeaderPage
                                text={`${headerText} - Frage ${questionCounter} von 
                                ${data.zeitfenster_katalog_fragen.length}`}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG2}
                                smallHeader
                            />
                            <AnswerPage
                                text={answerText}
                                correctAnswer={correctAnswer}
                                imageUrl={imageUrl}
                                next={handleNextQuestion}
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 4 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextQuestion}
                                text={data.further_infos_text}
                                imageUrl={data.further_infos_image.url}
                                blob={Blob9}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="zeitfenster"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 5 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextGame}
                                isLastPage
                                text={data.abschlusstext}
                                imageUrl={data.abschlussbild.url}
                                blob={Blob9}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="zeitfenster"
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default TimeWindowPage;
