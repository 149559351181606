import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const QuestionPage = ({
    questions,
    randomNumberGenerator,
    next,
    updateAnswer,
    updateImage,
    updateCorrectAnswer
}): JSX.Element => {
    const isSmallSize = useMediaQuery({ maxWidth: 820 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const [value, setValue] = React.useState('');
    const [randomIndex, setRandomIndex] = useState(-1);
    const timeout = 1000;

    let currentObject = {
        zeitfenster_antwortmoeglichkeiten: '',
        zeitfenster_fragen: '',
        zeitfenster_antworttext: '',
        zeitfenster_richtige_antwort: '',
        zeitfenster_teilausschnitt_bild: {
            url: ''
        }
    };
    let possibleAnswers: string[] = [];
    let imageUrl = '';
    let correctText = '';
    let answerText = '';

    if (randomIndex >= 0) {
        currentObject = questions[randomIndex];
        possibleAnswers =
            currentObject.zeitfenster_antwortmoeglichkeiten.split('\r\n');
        const index = +currentObject.zeitfenster_richtige_antwort - 1;
        correctText = possibleAnswers[index];
        answerText = currentObject.zeitfenster_antworttext;
        imageUrl = currentObject.zeitfenster_teilausschnitt_bild.url;
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        updateAnswer(answerText);
        updateImage(imageUrl);
        updateCorrectAnswer(correctText);
        setTimeout(next, timeout);
    };

    useEffect(() => {
        if (randomIndex < 0) {
            let index = randomNumberGenerator.next().value;
            while (index > Object.entries(questions).length - 1) {
                index = randomNumberGenerator.next().value;
            }
            if (index !== undefined) setRandomIndex(index);
            else next();
        }
    }, [questions, randomIndex, randomNumberGenerator, next]);

    return (
        <div style={{ marginTop: 50 }}>
            <Typography
                variant="h1"
                style={{
                    marginTop: isSmallSize && isLandscape ? 0 : 50,
                    textTransform: 'none'
                }}
            >
                {currentObject.zeitfenster_fragen}
            </Typography>
            <FormControl component="fieldset" style={{ marginTop: 50 }}>
                <RadioGroup
                    aria-label="question"
                    name="question"
                    value={value}
                    onChange={handleChange}
                >
                    {possibleAnswers.map((val) => (
                        <FormControlLabel
                            key={val}
                            value={val}
                            control={<Radio color="primary" />}
                            label={val}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default QuestionPage;
