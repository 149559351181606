import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import InfoPage from './InfoPage';
import HeaderPage from './HeaderPage';
import MainPage from '../timeBox/MainPage';
import InfoTextPage from './InfoTextPage';
import Blob5 from '../assets/GMU-JS-blob-5.png';
import { isEmpty, useQuery } from '../utils';
import gameBG from '../assets/zeitkapsel/bg.png';

const TimeBoxPage = (): JSX.Element => {
    const query = useQuery();
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState<any>();
    const [showError, setShowError] = useState(false);
    const [isInfoPage, setIsInfoPage] = useState(true);
    const [pageCounter, setPageCounter] = useState(0);
    const [numberOfGames, setNumberOfGames] = useState(0);
    const [shuffledDataArray, setShuffledDataArray] =
        useState<{ id: number; url: string; desc: string }[]>();
    const [reload, setReload] = useState(false);
    const headerText = 'Zeitkapsel';
    let id = 0;

    const paramId = query.get('id');
    if (data && paramId !== null && !Number.isNaN(paramId)) {
        const parseToInt = Number.parseInt(paramId, 10);
        if (
            !Number.isNaN(parseToInt) &&
            parseToInt < numberOfGames &&
            parseToInt >= 0
        )
            id = Number.parseInt(paramId, 10);
    }

    const handleNextPage = () => {
        setPageCounter(pageCounter + 1);
        setIsInfoPage(false);
    };

    const handleNextGame = () => {
        if (id < numberOfGames - 1) {
            id += 1;
        } else {
            id = 0;
        }
        history.push(`/zeitkapsel?id=${id}`);
        window.location.reload();
    };

    const handleShowInfoPage = () => {
        setIsInfoPage(true);
        setPageCounter(pageCounter - 1);
    };

    const shuffle = (sourceArray) => {
        const copySource = sourceArray;
        for (let i = 0; i < sourceArray.length - 1; i += 1) {
            const j = i + Math.floor(Math.random() * (sourceArray.length - i));

            const temp = sourceArray[j];
            copySource[j] = sourceArray[i];
            copySource[i] = temp;
        }
        return sourceArray;
    };

    useEffect(() => {
        if (!data || isEmpty(data)) return;
        const initData = () => {
            const dataArray = [
                {
                    id: 0,
                    url: data.hauptseite_felder.miniaturbild_1.sizes?.thumbnail,
                    desc: data.hauptseite_felder.miniaturbild1_bildbeschreibung
                },
                {
                    id: 1,
                    url: data.hauptseite_felder.miniaturbild_2.sizes?.thumbnail,
                    desc: data.hauptseite_felder.miniaturbild2_bildbeschreibung
                },
                {
                    id: 2,
                    url: data.hauptseite_felder.miniaturbild_3.sizes?.thumbnail,
                    desc: data.hauptseite_felder.miniaturbild3_bildbeschreibung
                }
            ];

            const shuffled = shuffle(dataArray);
            setShuffledDataArray(shuffled);
        };

        initData();
    }, [data, id]);

    useEffect(() => {
        if (shuffledDataArray && shuffledDataArray?.length > 0) {
            const shuffled = shuffle(shuffledDataArray);
            setShuffledDataArray(shuffled);
            if (reload) setReload(false);
        }
    }, [shuffledDataArray, reload]);

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await axios.get(
                    `${process.env.REACT_APP_ACF_URL}zeitkapsel?per_page=100`
                );
                if (fetchedData.status === 200) {
                    const mappedData = fetchedData.data.map(
                        (el) => el.acf.zeitkapselspiele[0].einzelspiele
                    );

                    setData(mappedData[id]);
                    setNumberOfGames(mappedData.length);
                    setIsLoaded(true);
                } else {
                    setShowError(true);
                }
            } catch (e) {
                setShowError(true);
            }
        }

        fetchData().then();
    }, [id]);

    return (
        <>
            {showError && (
                <Alert severity="error">
                    Spiel konnte nicht geladen werden. Überprüfe bitte deine
                    Internetverbindung.
                </Alert>
            )}
            {isLoaded && (
                <div>
                    {isInfoPage && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoPage
                                description={data.startseite_beschreibungstext}
                                manual={data.startseite_spielanleitung}
                                next={handleNextPage}
                                gameName="zeitkapsel"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 1 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <MainPage
                                imageText={data.credit}
                                text={
                                    data.hauptseite_felder.bildbeschreibungstext
                                }
                                imageUrl={data.hauptseite_felder.hauptbild.url}
                                dataArray={shuffledDataArray}
                                timeboxImg={
                                    data.hauptseite_felder.zeitkapselbild.url
                                }
                                animationIndex={
                                    shuffledDataArray &&
                                    shuffledDataArray.findIndex(
                                        (el) =>
                                            el.id ===
                                            +data.hauptseite_felder
                                                .richtige_antwort -
                                                1
                                    )
                                }
                                next={handleNextPage}
                                forceReload={() => setReload(true)}
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 2 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextPage}
                                text={data.abschlusstext}
                                imageUrl={data.abschlussbild.url}
                                finalWord=""
                                blob={Blob5}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="zeitkapsel"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 3 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextGame}
                                text=""
                                imageUrl={data.abschlussbild.url}
                                isLastPage
                                finalWord={data.abschlusssatz}
                                blob={Blob5}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="zeitkapsel"
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default TimeBoxPage;
