import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Typography } from '@material-ui/core';
import acceptBlob from '../assets/GMU-JS-blob-checkmark.png';
import rejectBlob from '../assets/GMU-JS-blob-x.png';

const textStyles = {
    textAlign: 'center' as const,
    color: 'black'
};

export default function RetryDialog({ text, onClose }) {
    const handleClose = (result) => {
        onClose(result);
    };

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="simple-dialog-title"
            open
        >
            <DialogContent style={{ background: '#f6f3ef' }}>
                <Typography variant="h5" style={textStyles}>
                    {text}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div
                        style={{ margin: 20, cursor: 'pointer' }}
                        role="presentation"
                        onClick={() => handleClose('yes')}
                    >
                        <img src={acceptBlob} alt="JA" width="50" height="50" />
                    </div>

                    <div
                        style={{ margin: 20, cursor: 'pointer' }}
                        role="presentation"
                        onClick={() => handleClose('no')}
                    >
                        <img src={rejectBlob} alt="JA" width="50" height="50" />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
