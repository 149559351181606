import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import ArrowIcon from '../assets/GMU-JS-blob-pfeil_rechts.png';
import EndAnimation from '../animations/EndAnimation';
import { getLogoUrlByGameName } from '../util';

const CustomInfoTextPage = ({
    next,
    isLastPage = false,
    text,
    portraitImage,
    blob,
    hasNoMoreGames,
    gameName
}): JSX.Element => {
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxHeight: 768 });
    const isDesktop = useMediaQuery({ minWidth: 1200 });

    const PhoneLandscape = ({ children }) =>
        isPhone && isLandscape ? children : null;

    const Normal = ({ children }) =>
        !(isPhone && isLandscape) ? children : null;

    return (
        <>
            <Normal>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: isLandscape ? '90vh' : 0 }}
                >
                    <Grid item xs={10} sm={isTabletLandscape ? 6 : 10} lg={4}>
                        <img
                            src={portraitImage.url}
                            alt="zeigt ein historisches Bild an"
                            width="100%"
                            style={{
                                maxHeight:
                                    // eslint-disable-next-line no-nested-ternary
                                    portraitImage.width > portraitImage.length
                                        ? isPhone
                                            ? 400
                                            : 600
                                        : isPhone
                                        ? 400
                                        : 600,
                                width:
                                    portraitImage.width > portraitImage.length
                                        ? '600px'
                                        : 'auto'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={isTabletLandscape ? 4 : 12} lg={3}>
                        <Typography
                            style={{
                                textAlign: 'center',
                                padding: isDesktop ? 50 : 20
                            }}
                            variant="h2"
                            dangerouslySetInnerHTML={{
                                __html: text
                            }}
                        />
                    </Grid>
                    <Grid item lg={1} />
                </Grid>
            </Normal>
            <PhoneLandscape>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={5}>
                        <img
                            src={portraitImage.url}
                            alt="zeigt ein historisches Bild an"
                            width="100%"
                            height="auto"
                            style={{
                                width: isLandscape ? '100%' : '70%',
                                height: isLandscape ? '75vh' : '50hv',
                                objectFit: 'scale-down'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ paddingLeft: 50, textAlign: 'left' }}>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: text
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </PhoneLandscape>

            {isLastPage ? <EndAnimation seconds={9} /> : null}

            <Button
                style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 10,
                    opacity: hasNoMoreGames ? 0.2 : 1
                }}
                onClick={next}
                disabled={hasNoMoreGames}
            >
                <img
                    src={getLogoUrlByGameName(gameName).right}
                    alt="Pfeilicon"
                    width={isPhone ? 70 : 130}
                />
            </Button>
        </>
    );
};

export default CustomInfoTextPage;
