import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import HeaderPageHome from './HeaderPageHome';

const buttonStyles = {
    margin: 20,
    padding: 30,
    minWidth: 170,
    borderRadius: 25
};
const colorButton1 = { backgroundColor: '#AF9BC8' };
const colorButton2 = { backgroundColor: '#9DB2E1' };
const colorButton3 = { backgroundColor: '#E19B7D' };
const colorButton4 = { backgroundColor: '#F0D232' };
const colorButton5 = { backgroundColor: '#E16E78' };

const HomePage = (): JSX.Element => {
    const history = useHistory();
    return (
        <div>
            <HeaderPageHome text="Spielübersicht" />
            <div>
                <Button
                    variant="contained"
                    style={{ ...buttonStyles, ...colorButton1 }}
                    onClick={() => {
                        history.push('/carousel?name=zeitfenster');
                    }}
                >
                    Zeitfenster
                </Button>
                <Button
                    variant="contained"
                    style={{ ...buttonStyles, ...colorButton2 }}
                    onClick={() => {
                        history.push('/carousel?name=motivsucher');
                    }}
                >
                    Motivsucher
                </Button>
            </div>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ ...buttonStyles, ...colorButton3 }}
                    onClick={() => {
                        history.push('/carousel?name=zeitkapsel');
                    }}
                >
                    Zeitkapsel
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ ...buttonStyles, ...colorButton4 }}
                    onClick={() => {
                        history.push('/carousel?name=zeitstrahl');
                    }}
                >
                    Zeitstrahl
                </Button>
                <Button
                    variant="contained"
                    style={{ ...buttonStyles, ...colorButton5 }}
                    onClick={() => {
                        history.push('/carousel?name=spurensuche');
                    }}
                >
                    Spurensuche
                </Button>
            </div>
        </div>
    );
};

export default HomePage;
