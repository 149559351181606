import React from 'react';
import { DragDropContainer } from 'react-drag-drop-container';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    root2: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function Thumbnails({
    thumbnails,
    counter,
    imgRatioWidth,
    isPhoneX = false
}): JSX.Element {
    const classes = useStyles();
    return (
        <div className={!isPhoneX ? classes.root : classes.root2}>
            {thumbnails.map(
                (thumbnail, index) =>
                    /* we need counter - 1 as well because otherwise the previous component is unmounted before
                     * setState could be finished which will
                     * lead to a react warning and performance issue */
                    (counter === index || counter - 1 === index) && (
                        <div key={`dragDrop_${thumbnail.miniaturbild.ID}`}>
                            <DragDropContainer
                                dragData={{ id: index }}
                                targetKey={`target${index}`}
                            >
                                <img
                                    src={thumbnails[index].miniaturbild.url}
                                    alt="Ein Ausschnitt wird angezeigt"
                                    style={{
                                        width:
                                            +thumbnail.miniaturbild.width *
                                            imgRatioWidth,
                                        height:
                                            +thumbnail.miniaturbild.height *
                                            imgRatioWidth,
                                        boxShadow: '0px 3px 6px #00000033',
                                        border: '5px solid #FFFFFF'
                                    }}
                                />
                            </DragDropContainer>
                        </div>
                    )
            )}
        </div>
    );
}
