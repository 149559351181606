import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DragDropContainer } from 'react-drag-drop-container';
import { useMediaQuery } from 'react-responsive';
import ColoredLine from './ColoredLine';
import { shuffleArray } from '../util';
import FlippedCard from './FlippedCard';

const tileStyles = {
    background: '#FFF2B2 0% 0% no-repeat padding-box',
    border: '5px solid #F0D232',
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const tileStylesPhoneLandscape = {
    ...tileStyles,
    width: 80,
    height: 80
};

const rowStylesLandscape = {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    margin: 30
};

const rowStylesPortrait = {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    flexDirection: 'column' as const,
    flexWrap: 'wrap' as const
};

const MainPage = ({ data, next }): JSX.Element => {
    const [dragStates, setDragStates] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    });

    const [flippedStates, setFlippedStates] = useState([
        false,
        false,
        false,
        false,
        false
    ]);

    const [counter, setCounter] = useState(0);
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPhone = useMediaQuery({ maxWidth: 850 });
    const isPhoneLandscape = isPhone && isLandscape;

    const cardFlipArray = useMemo(
        () =>
            data.hauptseite_felder
                .map((el) => +el.jahreszahl)
                .sort((a, b) => a - b),
        [data.hauptseite_felder]
    );

    const dragDropArray = useMemo(
        () => shuffleArray(data.hauptseite_felder),
        [data.hauptseite_felder]
    );

    const onHit = (event) => {
        if (!dragStates[event.dragData.id]) {
            setTimeout(() => {
                setDragStates({
                    ...dragStates,
                    [event.dragData.id]: true
                });
                setCounter(counter + 1);
                flippedStates[event.dragData.id - 1] = true;
                setFlippedStates([...flippedStates]);
            }, 50);
        }
    };

    const getIndexByValue = (value) =>
        data.hauptseite_felder.findIndex((el) => +el.jahreszahl === value);

    useEffect(() => {
        if (counter === data.hauptseite_felder.length) setTimeout(next, 1000);
    }, [counter, data.hauptseite_felder.length, next]);

    const CardFlipComponents = (): JSX.Element => (
        <>
            {cardFlipArray.map((value) => (
                <FlippedCard
                    index={getIndexByValue(value) + 1}
                    onHit={onHit}
                    isFlipped={flippedStates[getIndexByValue(value)]}
                    key={`flippedCard_${value}`}
                    cardData={
                        Object.values(data.hauptseite_felder)[
                            getIndexByValue(value)
                        ]
                    }
                    isPhoneLandscape={isPhoneLandscape}
                />
            ))}
        </>
    );

    const DragDropCards = (): JSX.Element => (
        <>
            {dragDropArray.map((cardData, index) => (
                <div
                    style={{
                        visibility: flippedStates[index] ? 'hidden' : 'visible',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    key={`dragDropContainer_${cardData.jahreszahl}`}
                >
                    <DragDropContainer
                        dragData={{ id: index + 1 }}
                        targetKey={`target${index + 1}`}
                    >
                        <div
                            style={{
                                ...tileStyles,
                                ...{
                                    width: isPhone ? 75 : 104,
                                    height: isPhone ? 75 : 104
                                }
                            }}
                        >
                            <img
                                src={
                                    cardData.historisches_bild.miniaturbild
                                        .sizes.thumbnail
                                }
                                alt={
                                    cardData.historisches_bild.miniaturbild.alt
                                }
                            />
                        </div>
                    </DragDropContainer>
                    <Typography variant="subtitle2" style={{ color: 'black' }}>
                        {cardData.historisches_bild.uberschrift}
                    </Typography>
                </div>
            ))}
        </>
    );

    return (
        <>
            {isLandscape && (
                <div>
                    <Typography
                        variant="h2"
                        style={{
                            marginTop: 30,
                            marginBottom: isPhone ? 0 : 50,
                            padding: '0 15%'
                        }}
                    >
                        Versuche, die Ereignisse in die richtige Reihenfolge vom
                        ältesten zum jüngsten zu bringen. Fang ganz links mit
                        dem ältesten an.
                    </Typography>
                    <Grid container alignContent="center">
                        <Grid item xs={12} style={rowStylesLandscape}>
                            {CardFlipComponents()}
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={1}>
                            <Typography variant="h5">Früher</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <ColoredLine />
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h5">Später</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center">
                        <Grid item xs={12} style={rowStylesLandscape}>
                            {DragDropCards()}
                        </Grid>
                    </Grid>
                </div>
            )}

            {isPortrait && (
                <>
                    <Typography variant="h2">
                        Versuche, die Ereignisse in die richtige Reihenfolge vom
                        ältesten zum jüngsten zu bringen. Fang ganz links mit
                        dem ältesten an.
                    </Typography>
                    <Typography>Früher</Typography>
                    <Grid
                        container
                        alignContent="center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid item xs={5} style={rowStylesPortrait}>
                            {DragDropCards()}
                        </Grid>
                        <Grid item style={rowStylesPortrait}>
                            <ColoredLine isVertical />
                        </Grid>
                        <Grid item xs={5} style={rowStylesPortrait}>
                            {CardFlipComponents()}
                        </Grid>
                    </Grid>
                    <Typography>Später</Typography>
                </>
            )}
        </>
    );
};

export default MainPage;
