import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import lerp from 'lerp';
import { range } from 'ramda';
import theme from '../../theme';
import Vector2 from './Vector2';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    pointer-events: none;
`;

const uid = (() => {
    let count = 0;
    return () => (count += 1);
})();

const createNewParticle = (angle = 0.6) => {
    const id = uid();
    const correctedAngle =
        angle * (id % 2 === 0 ? 1 : -1) +
        Math.PI +
        lerp(-Math.PI / 15, Math.PI / 15, Math.random());
    // const initialVelocity = lerp(10, window.innerWidth * 0.02, Math.random());

    const p = window.innerWidth / 800;

    const initialVelocity = lerp(10, 20, Math.random() * p);

    const x = id % 2 === 0 ? window.innerWidth + 50 : -50;
    const y = window.innerHeight * 0.9;

    return {
        id,
        width: lerp(4, 40, Math.random()),
        height: lerp(4, 20, Math.random()),
        rotation: Math.random() * Math.PI,
        rotationVelocity: lerp(-1, 1, Math.random()),
        position: new Vector2(x, y),
        velocity: new Vector2(
            Math.sin(correctedAngle) * initialVelocity,
            Math.cos(correctedAngle) * initialVelocity
        ),
        friction: lerp(0.97, 0.99, Math.random()),
        color: theme.animationPalette[
            Math.floor(Math.random() * (theme.animationPalette.length - 1)) + 1
        ]
    };
};

const GRAVITY = new Vector2(0, 0.1);

const drawParticle = (ctx, particle) => {
    // first save the untranslated/unrotated context

    const { position, width, height, rotation, color } = particle;
    const { x, y } = position;

    ctx.save();

    ctx.beginPath();
    // move the rotation point to the center of the rect
    ctx.translate(x + width / 2, y + height / 2);
    // rotate the rect
    ctx.rotate(rotation);

    // draw the rect on the transformed context
    // Note: after transforming [0,0] is visually [x,y]
    //       so the rect needs to be offset accordingly when drawn
    ctx.rect(-width / 2, -height / 2, width, height);

    // eslint-disable-next-line no-param-reassign
    ctx.fillStyle = color;
    ctx.fill();

    // restore the context to its untranslated/unrotated state
    ctx.restore();
};

const Confetti = ({
    burstAmount = 50,
    afterBurstAmount = 50,
    onEnd = () => {},
    ...restProps
}) => {
    const ref = useRef();
    const active = useRef(false);
    const particles = useRef(
        range(0, burstAmount).map(() => createNewParticle())
    );

    const maxParticles = burstAmount + afterBurstAmount;

    useLayoutEffect(() => {
        const canvas = ref && ref.current;
        const ctx =
            ref &&
            ref.current &&
            ref.current.getContext &&
            ref.current.getContext('2d');

        if (!ctx) {
            return;
        }
        active.current = true;

        let count = particles.current.length;
        const spawner = setInterval(() => {
            particles.current.push(createNewParticle());
            count += 1;

            if (count > maxParticles) {
                clearInterval(spawner);
            }
        }, 1000 / 30);

        const cleaner = setInterval(() => {
            particles.current = particles.current.filter(
                (particle) => particle.position.y < window.innerHeight
            );

            if (particles.current.length <= 0) {
                clearInterval(cleaner);
                onEnd();
            }
        }, 1000);

        const render = () => {
            if (!active.current) {
                return;
            }

            // Empty the canvas
            // eslint-disable-next-line
            canvas.width = canvas.width;

            for (let i = 0, n = particles.current.length; i < n; i += 1) {
                const particle = particles.current[i];

                if (particle.position.y > window.innerHeight) {
                    // eslint-disable-next-line no-continue
                    continue;
                }

                particle.velocity.multiplyScalar(particle.friction);
                particle.rotationVelocity *= particle.friction;

                particle.velocity.add(GRAVITY);
                particle.position.add(particle.velocity);
                particle.rotation += particle.rotationVelocity;

                drawParticle(ctx, particle);
            }
            requestAnimationFrame(render);
        };
        render();

        // eslint-disable-next-line consistent-return
        return () => {
            active.current = false;
            clearInterval(spawner);
            clearInterval(cleaner);
        };
    }, [ref, particles, maxParticles, onEnd]);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper {...restProps}>
            <canvas
                ref={ref}
                width={window.innerWidth}
                height={window.innerHeight}
            />
        </Wrapper>
    );
};

export default Confetti;
