import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Button, DialogContent, Grid } from '@material-ui/core';
import ExitIcon from '../assets/GMU-JS-blob-x.png';

export interface DialogComponentProps {
    onClose: () => void;
    infoText: string;
}

export default function InfoDialog(props: DialogComponentProps) {
    const { onClose, infoText } = props;

    return (
        <Dialog aria-labelledby="simple-dialog-title" open>
            <DialogTitle id="simple-dialog-title" />
            <DialogContent>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h6" style={{ color: 'black' }}>
                            {infoText}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <Button
                style={{ position: 'absolute', right: 10, top: 10 }}
                onClick={onClose}
            >
                <img src={ExitIcon} alt="exit icon" width={50} />
            </Button>
        </Dialog>
    );
}
