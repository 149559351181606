import {
    Button,
    Container,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { useMediaQuery } from 'react-responsive';
import acceptBtn from '../assets/Button.svg';
import acceptBtnMobile from '../assets/Button-mobile.svg';

export default function UserInput({ onClick }) {
    const [name, setName] = useState('');
    const [age, setAge] = useState(0);
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });

    const handleClick = () => {
        if (name !== '' && !Number.isNaN(age)) {
            const newObject = {
                jahreszahl: new Date().getFullYear() - age,
                historisches_bild: {
                    miniaturbild: {
                        sizes: {
                            thumbnail: 'blob'
                        }
                    },
                    uberschrift: name,
                    beschreibungstext: 'Hier bist du!'
                }
            };
            onClick(newObject);
        } else {
            setAge(0);
        }
    };

    return (
        <>
            {isDesktop && (
                <Container>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography
                                style={{
                                    fontSize: 36,
                                    marginBottom: 10,
                                    textAlign: 'left'
                                }}
                                variant={isPhone ? 'h6' : 'h2'}
                            >
                                Wo befindest du dich auf dem Zeitstrahl?
                            </Typography>
                        </Grid>
                        <Grid>
                            <form noValidate autoComplete="off" key="name">
                                <Grid
                                    container
                                    spacing={4}
                                    style={{ justifyContent: 'flex-start' }}
                                >
                                    <Grid item>
                                        <TextField
                                            InputLabelProps={{
                                                style: { color: '#B1B1B1' }
                                            }}
                                            InputProps={{
                                                style: { color: 'black' }
                                            }}
                                            key="name"
                                            id="outlined-basic"
                                            label="Name"
                                            variant="outlined"
                                            value={name}
                                            style={{
                                                background: 'white',
                                                color: 'black'
                                            }}
                                            onChange={(event) =>
                                                setName(event.target.value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            InputLabelProps={{
                                                style: { color: '#B1B1B1' }
                                            }}
                                            InputProps={{
                                                style: { color: 'black' }
                                            }}
                                            id="outlined-basic"
                                            label="Alter"
                                            variant="outlined"
                                            value={age}
                                            style={{
                                                background: 'white'
                                            }}
                                            onChange={(event) =>
                                                setAge(+event.target.value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <button
                                            type="button"
                                            style={{
                                                cursor: 'pointer',
                                                background: 'none',
                                                border: 'none',
                                                padding: 0,
                                                margin: -6
                                            }}
                                            onClick={handleClick}
                                        >
                                            <img
                                                src={
                                                    !isPhone
                                                        ? acceptBtn
                                                        : acceptBtnMobile
                                                }
                                                alt="accept"
                                            />
                                        </button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            )}
            {isPhone && (
                <div>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                        Wo befindest du dich auf dem Zeitstrahl?
                    </Typography>
                    <form noValidate autoComplete="off" key="name">
                        <Grid
                            container
                            spacing={2}
                            style={{ justifyContent: 'center' }}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#B1B1B1' }
                                    }}
                                    InputProps={{
                                        style: { color: 'black' }
                                    }}
                                    key="name"
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    value={name}
                                    style={{
                                        background: 'white',
                                        color: 'black'
                                    }}
                                    onChange={(event) =>
                                        setName(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#B1B1B1' }
                                    }}
                                    InputProps={{
                                        style: { color: 'black' }
                                    }}
                                    id="outlined-basic"
                                    label="Alter"
                                    variant="outlined"
                                    value={age}
                                    style={{
                                        background: 'white'
                                    }}
                                    onChange={(event) =>
                                        setAge(+event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <button
                                    type="button"
                                    style={{
                                        cursor: 'pointer',
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        margin: -6,
                                        width: 100
                                    }}
                                    onClick={handleClick}
                                >
                                    <img src={acceptBtnMobile} alt="accept2" />
                                </button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
        </>
    );
}
