import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import InfoPage from './InfoPage';
import HeaderPage from './HeaderPage';
import MainPage from '../motifFinder/MainPage';
import InfoTextPage from './InfoTextPage';
import Blob1 from '../assets/GMU-JS-blob-1.png';
import { useQuery } from '../utils';
import gameBG from '../assets/motivsucher/motivsucher.png';

const MotifFinderPage = (): JSX.Element => {
    const query = useQuery();
    const headerText = 'Motivsucher';
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [showError, setShowError] = useState<boolean>(false);
    const [pageCounter, setPageCounter] = useState(0);
    const [isInfoPage, setIsInfoPage] = useState(true);
    const [counter, setCounter] = useState(0);
    const [highlightStates, setHighlightStates] = useState({});
    const [numberOfGames, setNumberOfGames] = useState(0);
    let id: number | null = null;

    const paramId = query.get('id');
    if (data && paramId !== null && !Number.isNaN(paramId)) {
        const parseToInt = Number.parseInt(paramId, 10);
        if (
            !Number.isNaN(parseToInt) &&
            parseToInt < numberOfGames &&
            parseToInt >= 0
        )
            id = Number.parseInt(paramId, 10);
    }

    const handleNextPage = () => {
        setPageCounter(pageCounter + 1);
        setIsInfoPage(false);
    };

    const handleNextGame = () => {
        if (id == null) {
            id = 0;
        } else if (id < numberOfGames - 1) {
            id += 1;
        } else {
            id = 0;
        }
        history.push(`/motivsucher?id=${id}`);
        window.location.reload();
    };

    const handleShowInfoPage = () => {
        setIsInfoPage(true);
        setPageCounter(pageCounter - 1);
    };

    const nextImage = () => {
        if (counter + 1 < data.hauptseite_felder.length) {
            setCounter(counter + 1);
        } else {
            setTimeout(handleNextPage, 1500);
            setCounter(counter + 1);
        }
    };

    const highlight = (event) => {
        if (!highlightStates[event.dragData.id]) {
            // eslint-disable-next-line no-param-reassign
            event.containerElem.style.visibility = 'hidden';
            setHighlightStates({
                ...highlightStates,
                [event.dragData.id]: true
            });
            nextImage();
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await axios.get(
                    `${process.env.REACT_APP_ACF_URL}motivsucher?per_page=100`
                );
                if (fetchedData.status === 200) {
                    const mappedData = fetchedData.data.map(
                        (el) => el.acf.motivsucherspiele[0].einzelspiele
                    );

                    setData(mappedData[id ?? 0]);
                    setNumberOfGames(mappedData.length);
                    setIsLoaded(true);
                } else {
                    setShowError(true);
                }
            } catch (e) {
                setShowError(true);
            }
        }

        if (id !== -1) fetchData();
    }, [id]);
    console.log(123);
    return (
        <>
            {showError && (
                <Alert severity="error">
                    Spiel konnte nicht geladen werden. Überprüfe bitte deine
                    Internetverbindung.
                </Alert>
            )}
            {isLoaded && (
                <div>
                    {isInfoPage && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoPage
                                description={data.startseite_beschreibungstext}
                                manual={data.startseite_spielanleitung}
                                next={handleNextPage}
                                gameName="motivsucher"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 1 && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <MainPage
                                data={data}
                                next={handleNextPage}
                                thumbnails={data.hauptseite_felder}
                                highlight={highlight}
                                highlightStates={highlightStates}
                                counter={counter}
                                credit={data.bildcredit}
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 2 && (
                        <>
                            <HeaderPage
                                text={`${headerText}`}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextPage}
                                text={data.hauptbilder.bild_informationen}
                                imageUrl={data.hauptbilder.hauptbild.url}
                                counter={counter}
                                maxCounter={data.hauptseite_felder.length}
                                blob={Blob1}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="motivsucher"
                            />
                        </>
                    )}
                    {!isInfoPage && pageCounter === 3 && (
                        <>
                            <HeaderPage
                                text={`${headerText}`}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG}
                                smallHeader={false}
                            />
                            <InfoTextPage
                                next={handleNextGame}
                                isLastPage
                                text=""
                                imageUrl={data.hauptbilder.hauptbild.url}
                                counter={counter}
                                maxCounter={data.hauptseite_felder.length}
                                blob={Blob1}
                                hasNoMoreGames={numberOfGames <= 1}
                                gameName="motivsucher"
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default MotifFinderPage;
