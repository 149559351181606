import { Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const Tile = ({ title, subTitle, color, itemsLength, currentItem }) => {
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isPhoneLandscape = useMediaQuery({
        orientation: 'landscape',
        maxWidth: 1023
    });
    const isPhonePortrait = useMediaQuery({
        orientation: 'portrait',
        maxWidth: 480
    });

    const isTabletPortrait = useMediaQuery({
        maxWidth: 1023
    });
    const isTabletLandscape = useMediaQuery({
        maxWidth: 1623
    });

    let tileWidth = 500;
    if (isTabletLandscape) tileWidth = 400;

    if (isTabletPortrait) tileWidth = 450;
    if (isPhoneLandscape) tileWidth = 150;
    if (isPhonePortrait) tileWidth = 200;

    const tileStyles = {
        width: tileWidth,
        height: tileWidth,
        background: `${color} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 3px 6px #00000029',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '10px solid white',
        borderRadius: 5
    };

    const textStyles = {
        textAlign: 'center' as const,
        color: 'white'
    };

    return (
        <div style={{ ...tileStyles, ...{ position: 'relative' } }}>
            <Typography
                style={{
                    position: 'absolute',
                    top: '5%',
                    left: '5%',
                    fontSize: !isPhone ? 20 : 12
                }}
                variant={!isPhone ? 'h3' : 'h4'}
            >
                {`${currentItem}/${itemsLength}`}
            </Typography>
            <div style={textStyles}>
                <Typography
                    style={{
                        textAlign: 'center',
                        letterSpacing: '2.4px',
                        textTransform: 'uppercase'
                    }}
                    variant="h5"
                >
                    {title}
                </Typography>
                <Typography variant="h3">{subTitle}</Typography>
            </div>
        </div>
    );
};

export default Tile;
