import React from 'react';
import { DropTarget } from 'react-drag-drop-container';

export default function Placeholders({
    thumbnails,
    highlight,
    highlightStates,
    offsetLeft,
    imageWidth,
    imageHeight,
    imgRatioWidth
}): JSX.Element {
    return (
        <>
            {thumbnails.map((thumbnail, index) => {
                const width = +thumbnail.miniaturbild.width;
                const height = +thumbnail.miniaturbild.height;

                return (
                    <DropTarget
                        targetKey={`target${index}`}
                        onDragEnter={highlight}
                        onDragLeave={() => {}}
                        key={`dropTarget_${thumbnail.miniaturbild.ID}`}
                    >
                        <div
                            style={{
                                backgroundColor: highlightStates[index]
                                    ? 'transparent'
                                    : 'white',
                                border: `2px solid ${
                                    highlightStates[index]
                                        ? 'yellow'
                                        : 'transparent'
                                }`,
                                color: 'black',
                                width: width * imgRatioWidth,
                                height: height * imgRatioWidth,
                                position: 'absolute',
                                left:
                                    (imageWidth * thumbnail.bildposition.left) /
                                        100 -
                                    (+thumbnail.miniaturbild.width *
                                        imgRatioWidth) /
                                        2 +
                                    offsetLeft,
                                top:
                                    (imageHeight * thumbnail.bildposition.top) /
                                        100 -
                                    (+thumbnail.miniaturbild.height *
                                        imgRatioWidth) /
                                        2
                            }}
                        />
                    </DropTarget>
                );
            })}
        </>
    );
}
