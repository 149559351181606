import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';

export interface ItemDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

function SimpleDialog(props: ItemDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <Typography variant="h5">{selectedValue}</Typography>
        </Dialog>
    );
}

export default function ItemDialog({ text, onClose }) {
    return <SimpleDialog selectedValue={text} open onClose={onClose} />;
}
