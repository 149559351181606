import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import ChronoTimeline from './ChronoTimeline';
import { useWindowSize } from '../util';
import ArrowIcon from '../assets/GMU-JS-blob-pfeil_rechts.png';
import UserInput from './UserInput';
import Blob9 from '../assets/GMU-JS-blob-9.png';

export interface StyleProps {
    dimensions: { width: number; height: number };
    cardWidth: number;
    paddingTop: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    },
    tileStyles: {
        width: ({ cardWidth }) => cardWidth,
        height: ({ cardWidth }) => cardWidth,
        background: '#FFF2B2 0% 0% no-repeat padding-box',
        border: '1px solid #E4E4E4',
        borderRadius: 18,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowStyles: {
        display: 'flex',
        marginLeft: ({ dimensions }) => dimensions.width * 0.075 + 40
    },
    containerStyles: {
        paddingTop: ({ paddingTop }) => paddingTop
    }
}));

const SolvingPage = ({ data, next }): JSX.Element => {
    const size = useWindowSize();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const cardWidth = isPhone ? 80 : 104;
    const paddingTop = isPhone ? '0%' : '3%';
    const classes = useStyles({ dimensions, cardWidth, paddingTop });
    const yearComparator = (a, b) =>
        Number(a.jahreszahl) - Number(b.jahreszahl);

    const initArray = data.hauptseite_felder.sort(yearComparator);
    const [sortedArray, setSortedArray] = useState(initArray);

    const [items, setItems] = useState<any>([]);
    const [counter, setCounter] = useState(0);
    const timelineItemWidth = (dimensions.width * 0.8) / items.length;

    const Normal = ({ children }) =>
        !(isPhone && isPortrait) ? children : null;

    console.log(isPortrait);
    console.log(isPhone);

    const itemArray: {
        title: string;
        cardTitle: string;
        cardDetailedText: string;
    }[] = [];

    if (items.length === 0) {
        sortedArray.forEach((dataElement) => {
            const newObject = {
                title: '',
                cardTitle: dataElement.historisches_bild.uberschrift,
                cardDetailedText:
                    dataElement.historisches_bild.beschreibungstext
            };
            itemArray.push(newObject);
        });
        setItems([...itemArray]);
    }

    useEffect(() => {
        if (dimensions.width !== size.width) {
            dimensions.width = size.width;
            dimensions.height = size.height;
            setDimensions({ ...dimensions });
        }
    }, [size, dimensions, setDimensions]);

    const insertElement = (customObject) => {
        sortedArray.push(customObject);
        sortedArray.sort(yearComparator);
        setSortedArray([...sortedArray]);
        setCounter(counter + 1);
        setItems([]);
    };

    const CardItems = () =>
        sortedArray.map((cardItem, index) => (
            <div
                style={{
                    width: timelineItemWidth,
                    display: 'flex',
                    justifyContent: 'center'
                }}
                key={`cardItemsSolved_${cardItem.jahreszahl}${index}`}
            >
                <div className={classes.tileStyles}>
                    <img
                        src={
                            cardItem.historisches_bild.miniaturbild.sizes
                                .thumbnail === 'blob'
                                ? Blob9
                                : cardItem.historisches_bild.miniaturbild.sizes
                                      .thumbnail
                        }
                        alt={cardItem.historisches_bild.miniaturbild.alt}
                        width={
                            cardItem.historisches_bild.miniaturbild.sizes
                                .thumbnail === 'blob'
                                ? 70
                                : '100%'
                        }
                    />
                </div>
            </div>
        ));

    return (
        <>
            <Normal>
                <div style={{ textAlign: 'left', marginLeft: 120 }}>
                    <UserInput onClick={insertElement} />
                </div>
                <Grid
                    container
                    alignContent="center"
                    className={classes.containerStyles}
                >
                    <Grid item xs={12} className={classes.rowStyles}>
                        <CardItems />
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item xs={12} style={{ marginLeft: '7.5%' }}>
                        <ChronoTimeline
                            timelineItemWidth={timelineItemWidth}
                            mode="HORIZONTAL"
                            width={dimensions.width * 0.85}
                            height="90%"
                            items={items}
                        />
                    </Grid>
                </Grid>

                <Button
                    style={{ position: 'absolute', bottom: 0, right: 10 }}
                    onClick={next}
                >
                    <img
                        src={ArrowIcon}
                        alt="Pfeilicon"
                        width={isPhone ? 70 : 130}
                    />
                </Button>
            </Normal>

            {isPhone && isPortrait && (
                <>
                    <UserInput onClick={insertElement} />

                    <ChronoTimeline
                        timelineItemWidth={timelineItemWidth}
                        mode="VERTICAL"
                        width="100%"
                        height="100%"
                        items={items}
                        key={`timeline_${counter}`}
                    />
                    <Button onClick={next} style={{ float: 'right' }}>
                        <img src={ArrowIcon} alt="Pfeilicon" width={70} />
                    </Button>
                </>
            )}
        </>
    );
};

export default SolvingPage;
