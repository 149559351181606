import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { v4 as uuidv4 } from 'uuid';
import { getLogoUrlByGameName, useWindowSize } from '../util';
import mainPicture from '../assets/Historische_Karte.jpg';
import QuestionDialog from './QuestionDialog';
import ArrowIcon from '../assets/GMU-JS-blob-pfeil_rechts.png';
import InfoDialog from './InfoDialog';
import RetryDialog from '../components/RetryDialog';

function MainPage({
    portraits,
    next,
    historyMap,
    pictureCredit,
    portraitImage,
    gameName
}) {
    const containerRef = useRef<HTMLImageElement>(null);
    const size = useWindowSize();

    const isPhone = useMediaQuery({ maxWidth: 550 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isLaptop = useMediaQuery({ minWidth: 1200, maxHeight: 800 });
    const isPhoneLandscape = useMediaQuery({
        maxWidth: 950,
        orientation: 'landscape'
    });

    console.log(historyMap);
    const defaultImageWidth = historyMap.width * 1.6;
    const defaultImageHeight = historyMap.height * 1.6;
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [offsetTop, setOffsetTop] = useState(0);
    const [scaleFactor, setScaleFactor] = useState({ width: 1, height: 1 });
    const [highlightStates, setHighlightStates] = useState(
        Array(portraits.length).fill(false)
    );
    const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(
        Array(portraits.length).fill(0)
    );
    const [isDone, setIsDone] = useState(false);
    const [visitStates, setVisitStates] = useState(
        Array(portraits.length).fill(false)
    );
    const [isCompleted, setIsCompleted] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openRetryDialog, setOpenRetryDialog] = useState(false);
    let percentage: number;

    const tileWidthDesktop = 100;
    const tileHeightDesktop = 145;
    const tileWidthMobile = 65;
    const tileWidth = isPhone ? tileWidthMobile : tileWidthDesktop;
    const tileHeight = isPhone ? 100 : tileHeightDesktop;

    percentage = 60;
    if (isPortrait) percentage = 100;
    else if (isPhone && isLandscape) percentage = 100;
    else if (window.innerHeight < defaultImageHeight) {
        percentage = (window.innerHeight / defaultImageHeight) * 100 - 20;
    }

    const additionalScaleFactor = 100 / percentage;

    const infoTextNotDone =
        'Bitte klicke auf alle Bilder und beantworte die Fragen.';
    const infoTextNotCompleted =
        'Bitte sehe dir alle Bilder mit den entsprechenden Antworttexten nocheinmal genau an.';

    const onImageLoad = ({ target: img }) => {
        setScaleFactor({
            width: img.offsetWidth / defaultImageWidth,
            height: img.offsetHeight / defaultImageHeight
        });
    };

    const handleClick = (index) => {
        highlightStates[index] = true;
        setHighlightStates([...highlightStates]);
        setSelectedIndex(index);
        setOpenQuestionDialog(true);
        if (isDone) {
            visitStates[index] = true;
            setVisitStates([...visitStates]);
        }
    };

    const handleDialogClose = (answer) => {
        if (answer !== -1) {
            selectedAnswer[selectedIndex] = answer;
            setSelectedAnswer([...selectedAnswer]);
        }
        setOpenQuestionDialog(false);
        if (selectedAnswer.every((value) => value > 0)) {
            setIsDone(true);
        }
        if (visitStates.every((value) => value === true)) {
            setIsCompleted(true);
            setOpenRetryDialog(true);
        }
    };

    const handleNotCompleted = () => {
        setOpenInfoDialog(true);
    };

    const getBorderColor = (index) => {
        if (isDone) {
            if (portraits[index].frage_zum_ort.antwort) {
                return 'green';
            }
            return 'red';
        }
        if (highlightStates[index]) {
            return 'yellow';
        }
        return 'white';
    };

    const handleCloseRetryDialog = (value) => {
        setOpenRetryDialog(false);
        if (value === 'no') {
            next();
        } else {
            setIsCompleted(false);
            setIsDone(false);
            setHighlightStates(Array(portraits.length).fill(false));
            setVisitStates(Array(portraits.length).fill(false));
            setSelectedAnswer(Array(portraits.length).fill(0));
        }
    };

    useEffect(() => {
        const block = containerRef.current?.getBoundingClientRect();
        if (block !== undefined) {
            setOffsetLeft(+block.left * additionalScaleFactor);
            setOffsetTop(+block.top);
            setScaleFactor({
                width: block.width / defaultImageWidth,
                height: block.height / defaultImageHeight
            });
        }
    }, [size, additionalScaleFactor, defaultImageWidth, defaultImageHeight]);

    return (
        <>
            <Typography variant="h2">
                Klicke auf die Markierungen. Was hat die Person dort gemacht?
            </Typography>

            {isDesktop && (
                <div
                    style={{
                        position: 'relative',
                        width: defaultImageWidth * (isLaptop ? 0.5 : 0.7),
                        marginTop: 50,
                        marginLeft: '10%',
                        display: 'flex',
                        maxWidth: 750,
                        minWidth: isLaptop ? 550 : 750
                    }}
                >
                    <img
                        ref={containerRef}
                        src={historyMap ? historyMap.url : mainPicture}
                        alt="Hauptbild wird angezeigt"
                        style={{
                            maxWidth: 750,
                            width: defaultImageWidth * (isLaptop ? 0.5 : 0.7),
                            marginRight: '20%',
                            minWidth: isLaptop ? 550 : 750
                        }}
                        onLoad={onImageLoad}
                    />
                    <div style={{ display: 'block' }}>
                        <img
                            src={portraitImage.url}
                            alt="Portrait"
                            style={{
                                display: 'block',
                                maxHeight:
                                    portraitImage.width > portraitImage.length
                                        ? '400px'
                                        : '400px',
                                width:
                                    portraitImage.width > portraitImage.length
                                        ? '400px'
                                        : 'auto'
                            }}
                        />
                        <Typography variant="caption">
                            {pictureCredit}
                        </Typography>
                    </div>
                    <br />
                    {portraits.map((item, index) => (
                        <div
                            key={uuidv4()}
                            style={{
                                boxShadow: '0px 3px 0px #00000029',
                                border: '5px solid #fff',
                                borderColor: getBorderColor(index),
                                background:
                                    '#FFFFFF83 0% 0% no-repeat padding-box',
                                borderRadius: 50,
                                width: isPhone ? 60 : 70,
                                height: isPhone ? 60 : 70,
                                position: 'absolute',
                                left:
                                    (+item.bildposition.left / 100) *
                                        defaultImageWidth *
                                        scaleFactor.width -
                                    tileWidth / 2,
                                top:
                                    (+item.bildposition.top / 100) *
                                        defaultImageHeight *
                                        scaleFactor.height -
                                    tileHeight / 2
                            }}
                        >
                            <Button
                                style={{ padding: 0 }}
                                onClick={() => handleClick(index)}
                            >
                                <img
                                    src={item.portrait.url}
                                    alt={item.portrait.alt}
                                    style={{ maxWidth: 50 }}
                                />
                            </Button>
                        </div>
                    ))}
                </div>
            )}

            {isPortrait && (
                <>
                    <div
                        style={{
                            position: 'relative',
                            width: defaultImageWidth * (!isPhone ? 0.5 : 0.3),
                            marginTop: 50,
                            marginBottom: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            minWidth: isPhone ? 350 : 750,
                            maxWidth: isPhone ? 350 : 750
                        }}
                    >
                        <img
                            ref={containerRef}
                            src={historyMap ? historyMap.url : mainPicture}
                            alt="Hauptbild wird angezeigt"
                            style={{
                                maxWidth: isPhone ? 350 : 750,
                                width:
                                    defaultImageWidth * (!isPhone ? 0.5 : 0.3),
                                minWidth: isPhone ? 350 : 750
                            }}
                            onLoad={onImageLoad}
                        />

                        <br />
                        {portraits.map((item, index) => (
                            <div
                                key={uuidv4()}
                                style={{
                                    boxShadow: '0px 3px 0px #00000029',
                                    border: '5px solid #fff',
                                    borderColor: getBorderColor(index),
                                    background:
                                        '#FFFFFF83 0% 0% no-repeat padding-box',
                                    borderRadius: 50,
                                    width: isPhone ? 40 : 89,
                                    height: isPhone ? 40 : 89,
                                    position: 'absolute',
                                    left:
                                        (+item.bildposition.left / 100) *
                                            defaultImageWidth *
                                            scaleFactor.width -
                                        tileWidth / 2,
                                    top:
                                        (+item.bildposition.top / 100) *
                                            defaultImageHeight *
                                            scaleFactor.height -
                                        tileHeight / 2
                                }}
                            >
                                <Button
                                    style={{ padding: 0 }}
                                    onClick={() => handleClick(index)}
                                >
                                    <img
                                        src={item.portrait.url}
                                        alt={item.portrait.alt}
                                        style={{
                                            maxWidth: isPhone ? '50%' : '75%',
                                            marginRight: isPhone ? '25px' : 0
                                        }}
                                    />
                                </Button>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {isLandscape && !isDesktop && (
                <>
                    <div
                        style={{
                            position: 'relative',
                            width: defaultImageWidth * (!isPhone ? 0.5 : 0.3),
                            marginTop: isPhoneLandscape ? 25 : 50,
                            marginBottom: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            minWidth: isPhoneLandscape ? 350 : 600,
                            maxWidth: isPhoneLandscape ? 350 : 600
                        }}
                    >
                        <img
                            ref={containerRef}
                            src={historyMap ? historyMap.url : mainPicture}
                            alt="Hauptbild wird angezeigt"
                            style={{
                                minWidth: isPhoneLandscape ? 350 : 600,
                                maxWidth: isPhoneLandscape ? 350 : 600,
                                width:
                                    defaultImageWidth * (!isPhone ? 0.5 : 0.3)
                            }}
                            onLoad={onImageLoad}
                        />

                        <br />
                        {portraits.map((item, index) => (
                            <div
                                key={uuidv4()}
                                style={{
                                    boxShadow: '0px 3px 0px #00000029',
                                    border: '5px solid #fff',
                                    borderColor: getBorderColor(index),
                                    background:
                                        '#FFFFFF83 0% 0% no-repeat padding-box',
                                    borderRadius: 50,
                                    width: isPhoneLandscape ? 40 : 60,
                                    height: isPhoneLandscape ? 40 : 60,
                                    position: 'absolute',
                                    left:
                                        (+item.bildposition.left / 100) *
                                            defaultImageWidth *
                                            scaleFactor.width -
                                        tileWidth / 2,
                                    top:
                                        (+item.bildposition.top / 100) *
                                            defaultImageHeight *
                                            scaleFactor.height -
                                        tileHeight / 2
                                }}
                            >
                                <Button
                                    style={{ padding: 0 }}
                                    onClick={() => handleClick(index)}
                                >
                                    <img
                                        src={item.portrait.url}
                                        alt={item.portrait.alt}
                                        style={{
                                            maxWidth: isPhoneLandscape
                                                ? '50%'
                                                : '75%',
                                            marginRight: isPhoneLandscape
                                                ? '25px'
                                                : 0
                                        }}
                                    />
                                </Button>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {openQuestionDialog &&
                (selectedAnswer[selectedIndex] === 0 || isDone) && (
                    <QuestionDialog
                        onClose={handleDialogClose}
                        headerText={
                            portraits[selectedIndex].frage_zum_ort.uberschrift
                        }
                        questionText={
                            portraits[selectedIndex].frage_zum_ort.frage
                        }
                        answerText={
                            portraits[selectedIndex].frage_zum_ort.antworttext
                        }
                        url={portraits[selectedIndex].portrait.url}
                        isDone={isDone}
                        color={getBorderColor(selectedIndex)}
                        isWrong={
                            !portraits[selectedIndex].frage_zum_ort.antwort
                        }
                    />
                )}
            <Button
                style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 10,
                    opacity: isCompleted ? 1 : 0.2
                }}
                onClick={isCompleted ? next : handleNotCompleted}
            >
                <img
                    src={getLogoUrlByGameName(gameName).right}
                    alt="Pfeilicon"
                    width={isPhone || isLandscape ? 70 : 130}
                />
            </Button>
            {openInfoDialog && (
                <InfoDialog
                    onClose={() => setOpenInfoDialog(false)}
                    infoText={isDone ? infoTextNotCompleted : infoTextNotDone}
                />
            )}
            {openRetryDialog && (
                <RetryDialog
                    onClose={handleCloseRetryDialog}
                    text="Möchtest du es nochmal  versuchen?"
                />
            )}
        </>
    );
}

export default MainPage;
