import React, { useEffect, useState } from 'react';
import { Button, Fade, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import ArrowIcon from '../assets/GMU-JS-blob-pfeil_rechts.png';
import Blob from '../assets/GMU-JS-blob-sprechblase-1.svg';
import {
    getFontSize,
    getFontSizeForHeader,
    getLogoUrlByGameName
} from '../util';

const absolutePositionStyles = {
    position: 'absolute' as const,
    bottom: 0,
    right: 10
};

const positionRightStyles = {
    float: 'right' as const
};

const InfoPage = ({ description, manual, next, gameName }): JSX.Element => {
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isPhoneLandscape = useMediaQuery({
        maxWidth: 950,
        orientation: 'landscape'
    });
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setChecked(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <div
                style={{
                    textAlign: 'left',
                    marginLeft: 62,
                    marginRight: 40,
                    marginTop: isPhoneLandscape ? 0 : 60
                }}
            >
                <Typography
                    variant="h2"
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />

                <Fade in={checked}>
                    <div>
                        <Typography
                            variant="h2"
                            style={{
                                fontSize: isPhone ? 24 : 48,
                                marginBottom: 24
                            }}
                        >
                            Spielanleitung
                        </Typography>
                        <Typography
                            variant="h2"
                            dangerouslySetInnerHTML={{
                                __html: manual
                            }}
                        />
                    </div>
                </Fade>
            </div>
            <Button
                style={
                    isPhone && isLandscape
                        ? positionRightStyles
                        : absolutePositionStyles
                }
                onClick={next}
            >
                <Typography
                    variant="h4"
                    style={{
                        textTransform: 'capitalize',
                        marginRight: 10,
                        marginTop: -5
                    }}
                >
                    Start
                </Typography>
                <img
                    src={getLogoUrlByGameName(gameName).right}
                    alt="Pfeilicon"
                    width={isPhone ? 70 : 130}
                />
            </Button>
        </div>
    );
};

export default InfoPage;
