import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { useWindowSize } from '../util';
import LinearDeterminate from '../timeWindow/LinearDeterminate';
import Thumbnails from './Thumbnails';
import Placeholders from './Placeholders';

function MainPage({
    data,
    next,
    thumbnails,
    highlight,
    highlightStates,
    counter,
    credit
}) {
    const containerRef = useRef<HTMLImageElement>(null);
    const size = useWindowSize();

    const isPhone = useMediaQuery({ maxWidth: 1023, maxHeight: 500 });
    const isLaptop = useMediaQuery({ minWidth: 1200, maxHeight: 800 });
    const isSmallHeight = useMediaQuery({ minWidth: 1200, maxHeight: 670 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const origImageWidth = data.hauptbilder.hauptbild.width;
    const origImageHeight = data.hauptbilder.hauptbild.height;
    const imgDefaultWidth = origImageWidth;
    const imgDefaultHeight = origImageHeight;

    const [offsetLeft, setOffsetLeft] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    const [imgRatioWidth, setImgRatioWidth] = useState(
        imageWidth / imgDefaultWidth
    );

    const urlMainPicture = data.hauptbilder.hauptbild.url;
    const seconds = 30;
    let percentage;
    if (isLaptop) percentage = 75;
    else if (isSmallHeight) percentage = 60;
    else if (isPhone && isPortrait) percentage = 100;
    else if (isPhone) percentage = 90;
    else percentage = 90;

    const onImageLoad = ({ target: img }) => {
        setImageWidth(img.offsetWidth);
        setImageHeight(img.offsetHeight);
    };

    useEffect(() => {
        const block = containerRef.current?.getBoundingClientRect();
        if (block !== undefined) {
            setOffsetLeft((imageWidth * ((100 - percentage) / 2)) / 100);
            setImageWidth(block.width);
            setImageHeight(block.height);
            setImgRatioWidth(block.width / imgDefaultWidth);
        }
    }, [
        size,
        imageWidth,
        imageHeight,
        percentage,
        imgDefaultWidth,
        imgDefaultHeight
    ]);

    return (
        <>
            {!isPhone && (
                <>
                    <Container
                        style={{
                            marginTop: isLaptop ? -25 : 0
                        }}
                    >
                        <Typography variant="h2">{data.kurztitel}</Typography>
                        <Typography
                            variant="h5"
                            style={{
                                marginTop: 10,
                                marginBottom: isLaptop ? 10 : 35
                            }}
                        >
                            Zieh die Ausschnitte schnell an die richtigen
                            Stellen!
                        </Typography>
                        <div
                            style={{ position: 'relative', marginLeft: '10%' }}
                        >
                            <Grid container>
                                <Grid item xs={10}>
                                    <img
                                        ref={containerRef}
                                        src={urlMainPicture}
                                        alt="Hauptbild wird angezeigt"
                                        width={`${percentage}%`}
                                        onLoad={onImageLoad}
                                    />
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: credit
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Thumbnails
                                        thumbnails={thumbnails}
                                        counter={counter}
                                        imgRatioWidth={imgRatioWidth}
                                    />
                                </Grid>
                                <Placeholders
                                    thumbnails={thumbnails}
                                    highlight={highlight}
                                    highlightStates={highlightStates}
                                    offsetLeft={offsetLeft}
                                    imageWidth={imageWidth}
                                    imageHeight={imageHeight}
                                    imgRatioWidth={imgRatioWidth}
                                />
                            </Grid>
                        </div>
                    </Container>
                    <LinearDeterminate
                        fullWidth
                        seconds={seconds}
                        isDone={next}
                        style={{ margin: !isLaptop ? '50px 20px 0 20px' : 0 }}
                    />
                </>
            )}
            {isPhone && (
                <>
                    <Container>
                        <Grid container>
                            <Grid item xs={7}>
                                <div style={{ position: 'relative' }}>
                                    <img
                                        ref={containerRef}
                                        src={urlMainPicture}
                                        alt="Hauptbild wird angezeigt"
                                        width={`${percentage}%`}
                                        onLoad={onImageLoad}
                                    />
                                    <Placeholders
                                        thumbnails={thumbnails}
                                        highlight={highlight}
                                        highlightStates={highlightStates}
                                        offsetLeft={offsetLeft}
                                        imageWidth={imageWidth}
                                        imageHeight={imageHeight}
                                        imgRatioWidth={imgRatioWidth}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                    alignContent: 'center',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Thumbnails
                                    thumbnails={thumbnails}
                                    counter={counter}
                                    imgRatioWidth={imgRatioWidth}
                                    isPhoneX
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <LinearDeterminate
                        fullWidth
                        seconds={seconds}
                        isDone={next}
                        style={{ marginTop: -10 }}
                    />
                </>
            )}
        </>
    );
}

export default MainPage;
