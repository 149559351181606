import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { getLogoUrlByGameName } from '../util';

const AnswerPage = ({ text, imageUrl, correctAnswer, next }): JSX.Element => {
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPhone = useMediaQuery({ maxWidth: 850 });
    const isDesktop = useMediaQuery({ minWidth: 1350 });
    const isLaptop = useMediaQuery({ minWidth: 1350, maxWidth: 1650 });
    const isPhoneLandscape = useMediaQuery({ maxWidth: 950, maxHeight: 500 });
    const isTabletLandscape = useMediaQuery({
        maxWidth: 1100,
        orientation: 'landscape'
    });

    return (
        <div
            style={{
                margin: 12,
                marginTop: isDesktop || isPhoneLandscape ? 0 : 50
            }}
        >
            {!isPhoneLandscape && (
                <Grid container justify="flex-start">
                    <Grid item xs={12} lg={6}>
                        <div>
                            <Typography
                                variant="h3"
                                style={{
                                    textAlign: isDesktop ? 'left' : 'center',
                                    marginBottom: 20,
                                    marginLeft: isDesktop ? '15%' : 0,
                                    fontWeight: 'bold'
                                }}
                                component="div"
                            >
                                Richtige Antwort: {correctAnswer}
                            </Typography>
                            <img
                                style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                    maxHeight:
                                        isPhone || isPhoneLandscape ? 250 : 400
                                }}
                                src={imageUrl}
                                alt="zeigt ein historisches Bild an"
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={5}
                        style={{
                            marginLeft: isDesktop ? 50 : 10,
                            marginRight: isDesktop ? 0 : 10,
                            textAlign: 'left'
                        }}
                    >
                        <Typography
                            variant="h2"
                            style={{
                                textAlign: 'left',
                                marginTop: isDesktop ? 160 : 20
                            }}
                        >
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {isPhoneLandscape && (
                <Grid container justify="flex-start">
                    <Grid item xs={6} sm={6} lg={6}>
                        <div>
                            <Typography
                                variant="h3"
                                style={{
                                    textAlign: 'center',
                                    marginBottom: 20,
                                    marginLeft: isDesktop ? '15%' : 0,
                                    fontWeight: 'bold'
                                }}
                                component="div"
                            >
                                Richtige Antwort: {correctAnswer}
                            </Typography>
                            <img
                                style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                    maxHeight:
                                        isPhone || isPhoneLandscape ? 250 : 400
                                }}
                                src={imageUrl}
                                alt="zeigt ein historisches Bild an"
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        lg={5}
                        style={{
                            marginLeft: 20,
                            marginTop: 50,
                            marginRight: isDesktop ? 0 : 10,
                            textAlign: 'left'
                        }}
                    >
                        <Typography
                            variant="h2"
                            style={{
                                textAlign: 'left',
                                marginTop: isDesktop ? 160 : 20
                            }}
                        >
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <Button
                style={{
                    float: 'right',
                    marginTop: isLaptop || isPhoneLandscape ? -50 : 0
                }}
                onClick={next}
            >
                <Typography
                    style={{
                        textTransform: 'capitalize',
                        marginRight: 10,

                        marginTop: -5
                    }}
                    variant="h4"
                >
                    Weiter{' '}
                </Typography>

                <img
                    src={getLogoUrlByGameName('zeitfenster').right}
                    alt="Pfeilicon"
                    width={
                        isPhone || isPhoneLandscape || isTabletLandscape
                            ? 70
                            : 130
                    }
                />
            </Button>
        </div>
    );
};

export default AnswerPage;
