import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import InfoPage from './InfoPage';
import HeaderPage from './HeaderPage';
import Blob6 from '../assets/GMU-JS-blob-6.png';
import gameBG1 from '../assets/spurensuche/bg1.png';
import gameBG2 from '../assets/spurensuche/bg.jpg';
import PortraitPage from '../tracing/PortraitPage';
import MainPage from '../tracing/MainPage';
import CustomInfoTextPage from '../tracing/CustomInfoTextPage';
import { useQuery } from '../utils';

const TracingPage = (): JSX.Element => {
    const query = useQuery();
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [showError, setShowError] = useState<boolean>(false);
    const [pageCounter, setPageCounter] = useState(0);
    const [isInfoPage, setIsInfoPage] = useState(true);
    const [numberOfGames, setNumberOfGames] = useState(0);
    let id = 0;
    const headerText = 'Spurensuche';

    const paramId = query.get('id');
    if (data && paramId !== null && !Number.isNaN(paramId)) {
        const parseToInt = Number.parseInt(paramId, 10);
        if (
            !Number.isNaN(parseToInt) &&
            parseToInt < numberOfGames &&
            parseToInt >= 0
        )
            id = Number.parseInt(paramId, 10);
    }

    const handleNextPage = () => {
        setPageCounter(pageCounter + 1);
        setIsInfoPage(false);
    };

    const handleNextGame = () => {
        if (id < numberOfGames - 1) {
            id += 1;
        } else {
            id = 0;
        }
        history.push(`/spurensuche?id=${id}`);
        window.location.reload();
    };

    const handleShowInfoPage = () => {
        setIsInfoPage(true);
        setPageCounter(pageCounter - 1);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await axios.get(
                    `${process.env.REACT_APP_ACF_URL}spurensuche?per_page=100`
                );
                if (fetchedData.status === 200) {
                    const mappedData = fetchedData.data.map(
                        (el) => el.acf.spurensuchespiele[0].einzelspiele
                    );

                    setData(mappedData[id]);
                    setNumberOfGames(mappedData.length);
                    setIsLoaded(true);
                } else {
                    setShowError(true);
                }
            } catch (e) {
                setShowError(true);
            }
        }

        fetchData().then();
    }, [id]);

    return (
        <>
            {showError && (
                <Alert severity="error">
                    Spiel konnte nicht geladen werden. Überprüfe bitte deine
                    Internetverbindung.
                </Alert>
            )}
            {isLoaded && (
                <div>
                    {isInfoPage && (
                        <>
                            <HeaderPage
                                text={headerText}
                                showInfoPage={handleShowInfoPage}
                                color={gameBG1}
                                smallHeader={false}
                            />
                            <InfoPage
                                description={data.startseite_beschreibungstext}
                                manual={data.startseite_spielanleitung}
                                next={handleNextPage}
                                gameName="spurensuche"
                            />
                        </>
                    )}
                </div>
            )}
            {!isInfoPage && pageCounter === 1 && (
                <>
                    <HeaderPage
                        text={headerText}
                        showInfoPage={handleShowInfoPage}
                        color={gameBG1}
                        smallHeader={false}
                    />
                    <PortraitPage
                        next={handleNextPage}
                        textPartOne={data.person.beschreibungstext_seite_1}
                        textPartTwo={data.person.beschreibungstext_seite_2}
                        headerText={data.person.uberschrift}
                        pictureCredit={data.person.bildcredit}
                        imageUrl={data.person.portrait.url}
                        blob={Blob6}
                        gameName="spurensuche"
                    />
                </>
            )}
            {!isInfoPage && pageCounter === 2 && (
                <>
                    <HeaderPage
                        text={headerText}
                        showInfoPage={handleShowInfoPage}
                        color={gameBG2}
                        smallHeader={false}
                    />
                    <MainPage
                        historyMap={data.karte}
                        pictureCredit={data.person.bildcredit}
                        portraitImage={data.person.portrait}
                        portraits={data.stadtkarte}
                        next={handleNextPage}
                        gameName="spurensuche"
                    />
                </>
            )}
            {!isInfoPage && pageCounter === 3 && (
                <>
                    <HeaderPage
                        text={headerText}
                        showInfoPage={handleShowInfoPage}
                        color={gameBG1}
                        smallHeader={false}
                    />
                    <CustomInfoTextPage
                        next={handleNextGame}
                        isLastPage
                        text={data.abschlusstext}
                        portraitImage={data.abschlussbild}
                        blob={Blob6}
                        hasNoMoreGames={numberOfGames <= 1}
                        gameName="spurensuche"
                    />
                </>
            )}
        </>
    );
};

export default TracingPage;
