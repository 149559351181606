import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import Maax from './assets/fonts/Maax-Regular.woff';

const muiTheme = createMuiTheme({
    typography: {
        allVariants: {
            color: '#ffffff',
            fontFamily: ['Maax']
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                @font-face {
                  font-family: 'Maax';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Maax'), local('Maax'), url(${Maax}) format('woff');
                }
              `
            }
        },
        h1: {
            fontSize: 72,
            textTransform: 'capitalize',
            textAlign: 'center',
            fontWeight: 'bold',
            letterSpacing: 0,
            color: '#FFFFFF',
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 30
            },
            '@media (max-width:950px) and (orientation:landscape)': {
                fontSize: 30
            },
            '@media (max-width:1300px) and (min-width:1100px) and (orientation:landscape)':
                {
                    fontSize: 36
                }
        },
        h2: {
            fontSize: 36,
            letterSpacing: 0,
            fontWeight: 400,
            color: '#FFFFFF',
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 18
            },
            '@media (max-width:850px) and (orientation:portrait)': {
                fontSize: 18
            },
            '@media (max-width:1100px) and (orientation:landscape)': {
                fontSize: 20
            },
            '@media (max-width:950px) and (orientation:landscape)': {
                fontSize: 16
            },
            '@media (max-width:1300px) and (min-width:1100px) and (orientation:landscape)':
                {
                    fontSize: 36
                }
        },
        h5: {
            textAlign: 'center',
            fontSize: 24,
            letterSpacing: 0,
            color: '#FFFFFF',
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 14
            },
            '@media (max-width:850px) and (orientation:landscape)': {
                fontSize: 12
            }
        },
        h3: {
            fontWeight: 'bold',
            letterSpacing: 0,
            fontSize: 54,
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 24
            },
            '@media (max-width:850px) and (orientation:landscape)': {
                fontSize: 25
            },
            '@media (max-width:1600px) and (min-width:1100px) and (orientation:landscape)':
                {
                    fontSize: 36
                },
            '@media (max-width:1100px) and (orientation:landscape)': {
                fontSize: 36
            },
            '@media (max-width:950px) and (orientation:landscape)': {
                fontSize: 28
            }
        },
        h4: {
            fontSize: 48,
            letterSpacing: 0,
            fontWeight: 400,
            color: '#FFFFFF',
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 20
            },
            '@media (max-width:850px) and (orientation:landscape)': {
                fontSize: 20
            },
            '@media (max-width:1600px) and (min-width:1100px) and (orientation:landscape)':
                {
                    fontSize: 24
                }
        },
        h6: {
            fontSize: 18,
            '@media (max-width:550px) and (orientation:portrait)': {
                fontSize: 14
            },
            '@media (max-width:850px) and (orientation:landscape)': {
                fontSize: 16
            },
            '@media (max-width:1600px) and (min-width:1100px) and (orientation:landscape)':
                {
                    fontSize: 18
                }
        },
        subtitle2: {
            '@media (max-width:850px) and (orientation:landscape)': {
                fontSize: '0.6rem'
            }
        }
    },
    palette: {
        secondary: {
            main: '#ACC586'
        },
        text: {
            primary: '#ffffff'
        }
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                color: 'white',
                fontSize: '1.5rem',
                '@media (max-width:550px) and (orientation:portrait)': {
                    fontSize: '1.0rem'
                },
                '@media (max-width:950px) and (orientation:landscape)': {
                    fontSize: '1.2rem'
                },
                '@media (max-width:950px) and (orientation:portrait)': {
                    fontSize: '1.0rem'
                }
            }
        },
        MuiIconButton: {
            root: {
                color: 'white'
            },
            colorPrimary: 'white !important',
            colorSecondary: 'black'
        },
        MuiTextField: {
            root: {
                color: '#B1B1B1',
                '&::placeholder': {
                    color: '#B1B1B1'
                }
            }
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: '#B1B1B1'
                },
                color: 'red'
            }
        }
    },
    animationPalette: [
        '#0B1438',
        '#25DEB3',
        '#00A8FF',
        '#EE295C',
        '#FFF027',
        '#66BEEC'
    ]
});

const theme = responsiveFontSizes(muiTheme);
export default muiTheme;
