import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from 'react-responsive';
import {
    Button,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import ExitIcon from '../assets/GMU-JS-blob-x.png';

export interface DialogComponentProps {
    onClose: (value: number) => void;
    headerText: string;
    questionText: string;
    answerText: string;
    url: string;
    isDone: boolean;
    color: string;
    isWrong: boolean;
}

export default function QuestionDialog(props: DialogComponentProps) {
    const {
        onClose,
        headerText,
        questionText,
        url,
        answerText,
        isDone,
        color,
        isWrong
    } = props;
    const [answer, setAnswer] = React.useState(0);

    const borderColor =
        color === 'red' || color === 'green' ? color : 'transparent';

    const isSmallScreenSize = useMediaQuery({ maxWidth: 500 });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = +(event.target as HTMLInputElement).value;
        setAnswer(value);
    };

    useEffect(() => {
        if (answer > 0) setTimeout(() => onClose(answer), 1000);
    }, [answer, onClose]);

    return (
        <>
            {!isSmallScreenSize && (
                <Dialog
                    aria-labelledby="simple-dialog-title"
                    open
                    fullWidth={isSmallScreenSize}
                    fullScreen={isSmallScreenSize}
                >
                    <DialogTitle id="simple-dialog-title" />
                    <DialogContent>
                        <Grid container style={{ minWidth: 500 }}>
                            <Grid item xs={4} sm={4}>
                                <img
                                    src={url}
                                    alt="Portrait"
                                    width="auto"
                                    style={{
                                        maxHeight: '200px',
                                        border: `5px solid ${borderColor}`
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5} sm={8}>
                                <Typography
                                    variant="h4"
                                    style={{ color: '#00adb5' }}
                                >
                                    {headerText}
                                </Typography>
                                <Typography
                                    style={{ paddingTop: 20, color: '#000000' }}
                                >
                                    {isDone ? answerText : questionText}
                                </Typography>
                                {!isDone && (
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="question"
                                            name="question"
                                            value={answer}
                                            onChange={handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value={1}
                                                control={
                                                    <Radio color="secondary" />
                                                }
                                                label={
                                                    <Typography
                                                        style={{
                                                            color: 'black'
                                                        }}
                                                        variant="h6"
                                                    >
                                                        Richtig
                                                    </Typography>
                                                }
                                                color="#586875"
                                            />
                                            <FormControlLabel
                                                value={2}
                                                control={
                                                    <Radio color="secondary" />
                                                }
                                                label={
                                                    <Typography
                                                        style={{
                                                            color: 'black'
                                                        }}
                                                        variant="h6"
                                                    >
                                                        Falsch
                                                    </Typography>
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </DialogContent>
                    {isDone && (
                        <Button
                            style={{ position: 'absolute', right: 10, top: 10 }}
                            onClick={() => onClose(-1)}
                        >
                            <img src={ExitIcon} alt="exit icon" width={50} />
                        </Button>
                    )}
                </Dialog>
            )}

            {isSmallScreenSize && (
                <Dialog aria-labelledby="simple-dialog-title" open>
                    <DialogTitle id="simple-dialog-title" />
                    <DialogContent style={{ textAlign: 'center' }}>
                        <img
                            src={url}
                            alt="Portrait"
                            width="auto"
                            style={{
                                maxHeight: '200px',
                                border: `5px solid ${borderColor}`
                            }}
                        />
                        <Typography style={{ color: 'black' }} variant="h4">
                            {headerText}
                        </Typography>
                        <Typography variant="h6" style={{ color: 'black' }}>
                            {isDone ? answerText : questionText}
                        </Typography>
                        {!isDone && (
                            <FormControl
                                component="fieldset"
                                style={{ marginTop: 20 }}
                            >
                                <RadioGroup
                                    aria-label="question"
                                    name="question"
                                    value={answer}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label={
                                            <Typography
                                                style={{
                                                    color: 'black'
                                                }}
                                                variant="h6"
                                            >
                                                Richtig
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label={
                                            <Typography
                                                style={{
                                                    color: 'black'
                                                }}
                                                variant="h6"
                                            >
                                                Falsch
                                            </Typography>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    </DialogContent>
                    {isDone && (
                        <Button
                            style={{ position: 'absolute', right: 10, top: 10 }}
                            onClick={() => onClose(-1)}
                        >
                            <img src={ExitIcon} alt="exit icon" width={50} />
                        </Button>
                    )}
                </Dialog>
            )}
        </>
    );
}
