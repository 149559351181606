import React, { useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import ArrowIcon from '../assets/GMU-JS-blob-pfeil_rechts.png';
import { getLogoUrlByGameName } from '../util';

const absolutePositionStyles = {
    position: 'absolute' as const,
    bottom: 0,
    right: 10
};

const positionRightStyles = {
    float: 'right' as const
};

const PortraitPage = ({
    next,
    textPartOne,
    textPartTwo,
    headerText,
    pictureCredit,
    imageUrl,
    blob,
    gameName
}): JSX.Element => {
    const isPhone = useMediaQuery({ maxWidth: 850 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxHeight: 768 });
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const [counter, setCounter] = useState(0);
    const isPhoneLandscape = useMediaQuery({ maxWidth: 950, maxHeight: 500 });

    const PhoneLandscape = ({ children }) =>
        isPhone && isLandscape ? children : null;

    const Normal = ({ children }) =>
        !(isPhone && isLandscape) ? children : null;

    function nextPage() {
        if (counter === 0) setCounter(1);
        else {
            next();
        }
    }

    return (
        <>
            <Normal>
                <Container>
                    <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        spacing={2}
                        style={{
                            minHeight: isLandscape ? '80vh' : 0,
                            paddingTop: isPhone ? 20 : 50
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={isTabletLandscape ? 6 : 12}
                            lg={3}
                            xl={3}
                        >
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <img
                                        src={imageUrl}
                                        alt="zeigt ein historisches Bild an"
                                        style={{
                                            width: isDesktop ? '100%' : '40%',
                                            height: 'auto',
                                            objectFit: 'scale-down'
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        {pictureCredit}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={isTabletLandscape ? 6 : 12}
                            lg={8}
                            xl={8}
                            style={{ marginLeft: isTabletLandscape ? 0 : 50 }}
                        >
                            <Typography
                                variant="h4"
                                style={{
                                    textAlign: 'left',
                                    paddingTop: isPortrait ? 20 : 0,
                                    fontWeight: 'bold'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: headerText
                                }}
                            />
                            <Typography
                                variant="h5"
                                style={{
                                    textAlign: 'left',
                                    paddingTop: isPortrait ? 20 : 50
                                }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        counter === 0
                                            ? textPartOne
                                            : textPartTwo
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Normal>
            <PhoneLandscape>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={4}>
                        <Grid item>
                            <img
                                src={imageUrl}
                                alt="zeigt ein historisches Bild an"
                                width="100%"
                                style={{ maxWidth: 180, maxHeight: '70vh' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                {pictureCredit}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold'
                            }}
                            variant="h4"
                        >
                            {headerText}
                        </Typography>
                        <div style={{ paddingTop: 20, textAlign: 'left' }}>
                            <Typography
                                variant="h5"
                                style={{
                                    textAlign: 'left'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        counter === 0
                                            ? textPartOne
                                            : textPartTwo
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item />
                </Grid>
            </PhoneLandscape>

            <Button
                style={
                    isPortrait ? positionRightStyles : absolutePositionStyles
                }
                onClick={nextPage}
            >
                <Typography
                    style={{
                        textTransform: 'capitalize',
                        marginRight: 10,

                        marginTop: -5
                    }}
                    variant="h4"
                >
                    Weiter
                </Typography>
                <img
                    src={getLogoUrlByGameName(gameName).right}
                    alt="Pfeilicon"
                    width={
                        isPhone || isPhoneLandscape || isTabletLandscape
                            ? 70
                            : 130
                    }
                />
            </Button>
        </>
    );
};

export default PortraitPage;
