import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { animated as a, useSpring } from 'react-spring';
import { useHistory } from 'react-router';
import ItemDialog from './ItemDialog';
import RetryDialog from '../components/RetryDialog';

const imageStyles = {
    borderRadius: 10,
    boxShadow: '0px 3px 6px #0000003B',
    border: '3px solid #F8B275'
};

const MainPage = ({
    text,
    imageText,
    imageUrl,
    dataArray,
    timeboxImg,
    next,
    animationIndex,
    forceReload
}): JSX.Element => {
    const isPhone = useMediaQuery({ maxWidth: 1023 });
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const isIPadPro = useMediaQuery({ minWidth: 1024, minHeight: 1366 });
    const isIpad = useMediaQuery({ maxWidth: 768, minHeight: 1023 });
    const isLandscape = useMediaQuery({ orientation: 'landscape' });
    const isTabletLandscape = useMediaQuery({
        maxWidth: 1100,
        orientation: 'landscape'
    });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const [openDialog, setOpenDialog] = useState(false);
    const [isHit, setIsHit] = useState(false);
    const [dialogText, setDialogText] = useState(
        'Ich bin eine sehr coole Straßenbeleuchung!'
    );
    const [openRetryDialog, setOpenRetryDialog] = useState(false);
    const history = useHistory();
    let imageSize = 100;

    if (isPhone) {
        imageSize = 50;
    }
    if (isIpad) {
        imageSize = 100;
    }

    const contentProps = useSpring({
        opacity: isHit ? 0 : 1,
        marginTop: isHit ? -500 : 0,
        config: {
            delay: 0,
            duration: 3000
        },
        marginLeft: 10,
        width: isPhone ? 150 : 220,
        height: isPhone ? 232 : 357,
        background: '#fff 0% 0% no-repeat padding-box',
        borderRadius: isPhone ? 150 : 200,
        onRest: () => setTimeout(next, 2000)
    });

    const PhoneLandscape = ({ children }) =>
        isPhone && isLandscape ? children : null;

    const Desktop = ({ children }) => (isDesktop ? children : null);

    const Portrait = ({ children }) => (isPortrait ? children : null);

    const onDropTarget = (dragObj) => {
        if (!isHit) {
            if (dragObj.dragData.id === animationIndex)
                setTimeout(() => setIsHit(true), 100);
            else {
                setOpenRetryDialog(true);
            }
        }
    };

    const handleImageClick = (index) => {
        setOpenDialog(true);
        switch (index) {
            case 0:
                setDialogText(dataArray[0].desc);
                break;
            case 1:
                setDialogText(dataArray[1].desc);
                break;
            case 2:
                setDialogText(dataArray[2].desc);
                break;
            default:
                break;
        }
    };

    const handleCloseRetryDialog = (value) => {
        setOpenRetryDialog(false);
        if (value === 'no') {
            history.push('/');
        } else {
            forceReload();
        }
    };

    return (
        <>
            <Desktop>
                <Typography
                    variant="h2"
                    style={{ marginBottom: 20 }}
                    dangerouslySetInnerHTML={{
                        __html: `${text} - Was gab es noch nicht?`
                    }}
                />

                <Grid container alignItems="center" justify="center">
                    <Grid item xs={3}>
                        <div
                            style={
                                isHit && animationIndex === 0
                                    ? { visibility: 'hidden' }
                                    : undefined
                            }
                        >
                            <DragDropContainer
                                dragData={{ id: 0 }}
                                targetKey="target_zeitkapsel"
                            >
                                <Button onClick={() => handleImageClick(0)}>
                                    <img
                                        src={dataArray[0].url}
                                        alt="Bild 1"
                                        width={imageSize}
                                        style={imageStyles}
                                    />
                                </Button>
                            </DragDropContainer>
                        </div>
                        <div
                            style={
                                isHit && animationIndex === 1
                                    ? { visibility: 'hidden' }
                                    : undefined
                            }
                        >
                            <DragDropContainer
                                dragData={{ id: 1 }}
                                targetKey="target_zeitkapsel"
                            >
                                <Button onClick={() => handleImageClick(1)}>
                                    <img
                                        src={dataArray[1].url}
                                        alt="Bild 2"
                                        width={imageSize}
                                        style={imageStyles}
                                    />
                                </Button>
                            </DragDropContainer>
                        </div>
                        <div
                            style={
                                isHit && animationIndex === 2
                                    ? { visibility: 'hidden' }
                                    : undefined
                            }
                        >
                            <DragDropContainer
                                dragData={{ id: 2 }}
                                targetKey="target_zeitkapsel"
                            >
                                <Button onClick={() => handleImageClick(2)}>
                                    <img
                                        src={dataArray[2].url}
                                        alt="Bild 3"
                                        width={imageSize}
                                        style={imageStyles}
                                    />
                                </Button>
                            </DragDropContainer>
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{ flexDirection: 'row' }}>
                        <div>
                            <img
                                src={imageUrl}
                                alt="zeigt ein historisches Bild an"
                                style={{
                                    height: isLandscape ? 'auto' : '65vh',
                                    maxHeight: isTabletLandscape
                                        ? '40vh'
                                        : '55vh'
                                }}
                            />
                        </div>
                        <Typography
                            variant="h6"
                            dangerouslySetInnerHTML={{
                                __html: imageText
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <DropTarget
                            targetKey="target_zeitkapsel"
                            onDragEnter={onDropTarget}
                            onDragLeave={() => {}}
                            key="dropTarget_1"
                        >
                            <div className="container">
                                <a.div className="box" style={contentProps}>
                                    <img
                                        src={timeboxImg}
                                        alt="Zeitkapselbild"
                                        style={{
                                            marginTop: 50,
                                            maxWidth: 200
                                        }}
                                    />
                                    <Typography
                                        style={{ color: 'black', fontSize: 16 }}
                                    >
                                        Zeitkapsel
                                    </Typography>
                                </a.div>
                            </div>
                        </DropTarget>
                    </Grid>
                </Grid>
            </Desktop>
            <Portrait>
                <Typography variant="h2" style={{ marginBottom: 20 }}>
                    {text}
                </Typography>

                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} style={{ flexDirection: 'column' }}>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%'
                            }}
                        >
                            <div>
                                <img
                                    src={imageUrl}
                                    alt="zeigt ein historisches Bild an"
                                    style={{
                                        maxWidth: '70%',
                                        maxHeight: '100%',

                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                />
                            </div>
                            <Typography
                                variant="h6"
                                style={{ marginBottom: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: imageText
                                }}
                            />

                            <div style={{ margin: '0 0 10px 0' }}>
                                <div
                                    style={{
                                        width: '30%',
                                        display: 'inline-block',
                                        visibility:
                                            isHit && animationIndex === 0
                                                ? 'hidden'
                                                : 'visible'
                                    }}
                                >
                                    <DragDropContainer
                                        dragData={{ id: 0 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(0)}
                                        >
                                            <img
                                                src={dataArray[0].url}
                                                alt="Bild 1"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                                <div
                                    style={{
                                        width: '30%',
                                        display: 'inline-block',
                                        visibility:
                                            isHit && animationIndex === 1
                                                ? 'hidden'
                                                : 'visible'
                                    }}
                                >
                                    <DragDropContainer
                                        dragData={{ id: 1 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(1)}
                                        >
                                            <img
                                                src={dataArray[1].url}
                                                alt="Bild 2"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                                <div
                                    style={{
                                        width: '30%',
                                        display: 'inline-block',
                                        visibility:
                                            isHit && animationIndex === 2
                                                ? 'hidden'
                                                : 'visible'
                                    }}
                                >
                                    <DragDropContainer
                                        dragData={{ id: 2 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(2)}
                                        >
                                            <img
                                                src={dataArray[2].url}
                                                alt="Bild 3"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={isIPadPro ? 2 : 7} lg={2}>
                        <DropTarget
                            targetKey="target_zeitkapsel"
                            onDragEnter={onDropTarget}
                            onDragLeave={() => {}}
                            key="dropTarget_1"
                        >
                            <div
                                className="container"
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                            >
                                <a.div className="box" style={contentProps}>
                                    <img
                                        src={timeboxImg}
                                        alt="Zeitkapselbild"
                                        style={{ marginTop: 25, maxWidth: 150 }}
                                    />
                                    <Typography
                                        style={{ color: 'black', fontSize: 16 }}
                                    >
                                        Zeitkapsel
                                    </Typography>
                                </a.div>
                            </div>
                        </DropTarget>
                    </Grid>
                </Grid>
            </Portrait>
            <PhoneLandscape>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={6} style={{ position: 'relative' }}>
                        <img
                            src={imageUrl}
                            alt="zeigt ein historisches Bild an"
                            width="100%"
                            style={{ maxHeight: 310 }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: 10,
                                left: 10
                            }}
                        >
                            <div>
                                <div
                                    style={
                                        isHit && animationIndex === 0
                                            ? { visibility: 'hidden' }
                                            : undefined
                                    }
                                >
                                    <DragDropContainer
                                        dragData={{ id: 0 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(0)}
                                        >
                                            <img
                                                src={dataArray[0].url}
                                                alt="Bild 1"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                                <div
                                    style={
                                        isHit && animationIndex === 1
                                            ? { visibility: 'hidden' }
                                            : undefined
                                    }
                                >
                                    <DragDropContainer
                                        dragData={{ id: 1 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(1)}
                                        >
                                            <img
                                                src={dataArray[1].url}
                                                alt="Bild 2"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                                <div
                                    style={
                                        isHit && animationIndex === 2
                                            ? { visibility: 'hidden' }
                                            : undefined
                                    }
                                >
                                    <DragDropContainer
                                        dragData={{ id: 2 }}
                                        targetKey="target_zeitkapsel"
                                    >
                                        <Button
                                            onClick={() => handleImageClick(2)}
                                        >
                                            <img
                                                src={dataArray[2].url}
                                                alt="Bild 3"
                                                width={imageSize}
                                                style={imageStyles}
                                            />
                                        </Button>
                                    </DragDropContainer>
                                </div>
                            </div>
                        </div>
                        <Typography>{text}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <DropTarget
                            targetKey="target_zeitkapsel"
                            onHit={onDropTarget}
                        >
                            <div className="container">
                                <a.div className="box" style={contentProps}>
                                    <img
                                        src={timeboxImg}
                                        alt="Zeitkapselbild"
                                        style={{ marginTop: 50, maxWidth: 150 }}
                                    />
                                    <Typography
                                        style={{ color: 'black', fontSize: 16 }}
                                    >
                                        Zeitkapsel
                                    </Typography>
                                </a.div>
                            </div>
                        </DropTarget>
                    </Grid>
                </Grid>
            </PhoneLandscape>
            {openDialog && (
                <ItemDialog
                    text={dialogText}
                    onClose={() => setOpenDialog(false)}
                />
            )}
            {openRetryDialog && (
                <RetryDialog
                    onClose={handleCloseRetryDialog}
                    text="Du hast leider falsch geraten! Möchtest du es nochmal  versuchen?"
                />
            )}
        </>
    );
};

export default MainPage;
