import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMediaQuery } from 'react-responsive';

export interface StyleProps {
    fullWidth: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        width: ({ fullWidth }) => (fullWidth ? '100%' : '80%')
    }
}));

const LinearDeterminate = ({
    seconds,
    isDone,
    style = {},
    fullWidth = false
}): JSX.Element => {
    const classes = useStyles({ fullWidth });
    const [progress, setProgress] = React.useState(0);

    const isSmallHeight = useMediaQuery({ maxHeight: 500 });

    useEffect(() => {
        const timer = setInterval(() => {
            if (progress < 100) {
                setProgress((oldProgress) => {
                    if (oldProgress > 100) {
                        return 100;
                    }
                    const singleStep = 100 / (seconds * 10);
                    return oldProgress + singleStep;
                });
            } else {
                isDone();
            }
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, [seconds, isDone, progress]);

    return (
        <div style={{ ...style }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={classes.root}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color="secondary"
                        style={{
                            height: isSmallHeight ? 10 : 35,
                            background: '#EBEBEB 0% 0% no-repeat padding-box',
                            boxShadow: '0px 3px 6px #00000029',
                            color: '#ABE8A3',
                            borderRadius: 20,
                            border: '3px solid #EBEBEB'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default LinearDeterminate;
