import React, { useCallback, useEffect, useState } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import Tile from './Tile';
import { useQuery } from '../../utils';

import Logo from '../../assets/stadtmuseum_graz_logo.png';
import { getBackgroundImageByName, getLogoUrlByGameName } from '../../util';

const arrowsStylesPhone = {
    display: 'flex',
    justifyContent: 'space-evenly'
};

const arrowsStyles = {
    display: 'flex',
    justifyContent: 'center'
};

const MiniGamesCarousel = (): JSX.Element => {
    const initState = {
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: false,
        config: config.slow,
        isSelected: false
    };

    const history = useHistory();
    const query = useQuery();
    const [state, setState] = useState(initState);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showError, setShowError] = useState(false);
    const [arrowLeft, setArrowLeft] = useState('');
    const [arrowRight, setArrowRight] = useState('');
    const [bg, setBg] = useState('');
    const [games, setGames] = useState<any>([]);

    const isPhoneLandscape = useMediaQuery({
        orientation: 'landscape',
        maxWidth: 950
    });
    const isPhonePortrait = useMediaQuery({
        orientation: 'portrait',
        maxWidth: 580
    });

    const isSmallWidth = useMediaQuery({ maxWidth: 400 });
    const isPhone = useMediaQuery({ maxWidth: 550 });
    const isTabletLandscape = useMediaQuery({
        maxWidth: 1100,
        orientation: 'landscape'
    });
    const isLaptop = useMediaQuery({ maxWidth: 1600, minWidth: 1300 });

    const name = query.get('name') || '';

    const getArrowColorIcon = useCallback(() => {
        const arrows = getLogoUrlByGameName(name);

        if (arrows) {
            setArrowLeft(arrows.left);
            setArrowRight(arrows.right);
        }
    }, [name]);

    const getBackgroundImage = useCallback(() => {
        const image = getBackgroundImageByName(name);

        setBg(image);
    }, [name]);

    let height = 550;

    if (isTabletLandscape) height = 450;
    if (isPhoneLandscape) height = 100;

    if (isLaptop) height = 450;
    else if (isPhonePortrait) height = 300;

    if (state.isSelected) {
        history.push(`/${name}?id=${state.goToSlide}`, {
            from: 'MiniGamesCarousel'
        });
    }

    let slides: { key: number; content: JSX.Element }[] = [];

    function addSlides(items, color) {
        items.forEach((tile, index) => {
            const title = tile.beschriftung_carousel.hauptueberschrift;
            const topic = tile.beschriftung_carousel.unterueberschrift;
            const obj = {
                key: uuidv4(),
                content: (
                    <Tile
                        itemsLength={items.length}
                        currentItem={index + 1}
                        title={title}
                        subTitle={topic}
                        color={color}
                    />
                )
            };
            slides.push(obj);
        });

        slides = slides.map((slide, index) => ({
            ...slide,
            onClick: () =>
                setState({ ...state, goToSlide: index, isSelected: true })
        }));
    }

    if (isLoaded) {
        switch (name) {
            case 'zeitkapsel':
                addSlides(games, '#E19B7D');
                break;
            case 'zeitstrahl':
                addSlides(games, '#F0D232');
                break;
            case 'motivsucher':
                addSlides(games, '#5060E6');
                break;
            case 'spurensuche':
                addSlides(games, '#E16E78');
                break;
            case 'zeitfenster':
                addSlides(games, '#F0D232');
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await axios.get(
                    `${process.env.REACT_APP_ACF_URL}${name}?per_page=100`
                );

                if (fetchedData.status === 200) {
                    const data = fetchedData.data.map(
                        (el) => el.acf[`${name}spiele`][0].einzelspiele
                    );
                    setGames(data);
                    setIsLoaded(true);
                } else {
                    setShowError(true);
                }
            } catch (e) {
                setShowError(true);
            }
        }

        fetchData().then();
        getArrowColorIcon();
        getBackgroundImage();
    }, [getArrowColorIcon, getBackgroundImage, name]);

    let xDown = 0;
    let yDown = 0;

    const getTouches = (evt) => evt.touches || evt.originalEvent.touches; // browser API // jQuery
    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;
        if (xDown && yDown) {
            const xDiff = xDown - xUp;
            const yDiff = yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    /* left swipe */
                    setState({
                        goToSlide: state.goToSlide + 1,
                        offsetRadius: 2,
                        showNavigation: false,
                        config: config.slow,
                        isSelected: false
                    });
                } else {
                    /* right swipe */
                    setState({
                        goToSlide: state.goToSlide - 1,
                        offsetRadius: 2,
                        showNavigation: false,
                        config: config.slow,
                        isSelected: false
                    });
                }
            } else if (yDiff > 0) {
                /* up swipe */
            } else {
                /* down swipe */
            }
            /* reset values */
            xDown = 0;
            yDown = 0;
        }
    };

    return (
        <div>
            {showError && (
                <Alert severity="error">
                    Spiel konnte nicht geladen werden. Bitte überprüfe deine
                    Internetverbindung.
                </Alert>
            )}
            {isLoaded && (
                <div>
                    <Helmet>
                        <style>{`body { background-image: url("${bg}"); background-size:cover;
             }`}</style>
                    </Helmet>
                    <Grid container>
                        <Grid
                            item
                            xs={3}
                            lg={3}
                            style={{
                                textAlign: 'left',
                                paddingLeft: isPhone ? 5 : 42,
                                paddingTop: isPhone ? 5 : 24
                            }}
                        >
                            <a href="https://www.grazmuseum.at/jungestadt">
                                <img
                                    src={Logo}
                                    alt="Stadtmuseum Graz Logo"
                                    width={isPhone ? 50 : 100}
                                />
                            </a>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                                paddingTop:
                                    isLaptop ||
                                    isPhoneLandscape ||
                                    isTabletLandscape
                                        ? 24
                                        : 80
                            }}
                        >
                            <Typography
                                style={{
                                    marginBottom:
                                        isLaptop ||
                                        isPhoneLandscape ||
                                        isTabletLandscape
                                            ? 8
                                            : 18
                                }}
                                variant="h1"
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="h2"
                                style={{
                                    marginBottom: isLaptop ? 8 : 18
                                }}
                            >
                                Wähle ein Spiel aus.
                            </Typography>
                        </Grid>
                        <Grid xs={3} />
                    </Grid>

                    <div
                        style={{
                            width: isSmallWidth ? '100%' : '80%',
                            height,
                            margin: '0 auto'
                        }}
                    >
                        <div
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            style={{
                                width: isSmallWidth ? '100%' : '80%',
                                height,
                                margin: '0 auto'
                            }}
                        >
                            <Carousel
                                slides={slides}
                                goToSlide={state.goToSlide}
                                offsetRadius={state.offsetRadius}
                                showNavigation={state.showNavigation}
                                animationConfig={state.config}
                            />
                        </div>
                        <div
                            style={
                                isSmallWidth ? arrowsStylesPhone : arrowsStyles
                            }
                        >
                            <div
                                role="presentation"
                                style={{
                                    cursor: 'pointer',
                                    padding: isPhoneLandscape ? 40 : '10px 32px'
                                }}
                                onClick={() => {
                                    setState({
                                        goToSlide: state.goToSlide - 1,
                                        offsetRadius: 2,
                                        showNavigation: false,
                                        config: config.slow,
                                        isSelected: false
                                    });
                                }}
                            >
                                <img
                                    alt="LefTArrow"
                                    src={arrowLeft}
                                    width={isPhoneLandscape ? 70 : 100}
                                />
                            </div>

                            <div
                                role="presentation"
                                style={{
                                    cursor: 'pointer',
                                    padding: isPhoneLandscape ? 40 : '10px 32px'
                                }}
                                onClick={() => {
                                    setState({
                                        goToSlide: state.goToSlide + 1,
                                        offsetRadius: 2,
                                        showNavigation: false,
                                        config: config.slow,
                                        isSelected: false
                                    });
                                }}
                            >
                                <img
                                    alt="RightArrow"
                                    src={arrowRight}
                                    width={isPhoneLandscape ? 70 : 100}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MiniGamesCarousel;
