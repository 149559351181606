import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import Logo from '../assets/stadtmuseum_graz_logo.png';

const HeaderPage = ({ text }): JSX.Element => {
    const isTinySize = useMediaQuery({ maxWidth: 400 });

    return (
        <div style={{ height: isTinySize ? 75 : 50 }}>
            <Helmet>
                <style>{`body {
				 background: rgb(2,0,36);
                 background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(72,81,237,1) 0%, rgba(179,154,204,1) 100%);
				 }`}</style>
            </Helmet>
            <Grid
                container
                alignContent="center"
                justify="center"
                alignItems="center"
                style={{ marginTop: 20 }}
            >
                <Grid item xs={2}>
                    <a href="https://www.grazmuseum.at/jungestadt">
                        <img
                            src={Logo}
                            alt="Stadtmuseum Graz Logo"
                            width="40vw"
                        />
                    </a>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        variant="h6"
                        style={{
                            padding: 8,
                            color: 'white',
                            fontFamily: 'sans-serif'
                        }}
                    >
                        {text}
                    </Typography>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </div>
    );
};

export default HeaderPage;
